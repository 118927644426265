<template>
  <div class="modal-card">
    <header class="modal-card-head" style="padding: 10px;">
      <p class="modal-card-title">Time</p>
    </header>
    <section class="modal-card-body">
      <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <div v-if="!loading">
        <b-field label="Days" v-if="DataIn.showDays">
          <b-numberinput size="is-small" min="0" v-model="days" @input="recalc"></b-numberinput>
        </b-field>
        <b-field label="Hours" v-if="DataIn.showHours">
          <b-numberinput size="is-small" min="0" max="23" v-model="hours" @input="recalc"></b-numberinput>
        </b-field>
        <b-field label="Minutes" v-if="DataIn.showMinutes">
          <b-numberinput size="is-small" v-model="minutes" step="15" min="0" max="45" @input="recalc"></b-numberinput>
        </b-field>
        <b-field label="Minutes" v-if="DataIn.showMinutesFull">
          <b-numberinput size="is-small" v-model="minutes" step="15" min="0" :max="DataIn.minsLimit" @input="recalc"></b-numberinput>
        </b-field>
        <div style="height: 20px;"></div>
        <div v-if="DataIn.showDaysNeeded">
          Days: {{ daysNeeded }} 
        </div>
        <div v-if="DataIn.showNeeded">
          Day Max: {{ DataIn.minsLimit }} 
        </div>
        <div v-if="DataIn.showNeeded">
          Total needed: {{ DataIn.minsTotal }} 
        </div>
        <div v-if="DataIn.showNeeded">
          Allocated: {{ DataIn.minsAllocated }} 
        </div>
        <div v-if="DataIn.showNeeded">
          Remaining: {{ DataIn.minsTotal - DataIn.minsAllocated }} 
        </div>
      </div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Cancel
        </b-button>
      </div>
      <div>
        <b-button @click="submitForm(true)">
          Allocate
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
export default {
  name: "ModalTime",
  mixins: [Utils],
  props: {
    data: Object,
  },
  data() {
    return {
      DataIn: this.data,
      days: 0,
      hours: 0,
      minutes: this.data.inmins,
      loading: true,
      maxminutes: 90,
      daysNeeded: 0,
      Me: {}
    };
  },
  created() {
    this.getMe();
  },
  methods: {
    async getMe() {
      // this.Me = await this.DataGet('/me');
      this.loading = false;
    },
    recalc() {
      const hd = this.data.mehours;
      let totalmins = 0;
      const totalDaysInMinutes = (this.days * hd) * 60;
      totalmins += totalDaysInMinutes;
      totalmins += this.hours * 60;
      totalmins += this.minutes;
      this.DataIn.minsAllocated = totalmins;
      this.daysNeeded = Math.ceil(this.DataIn.minsTotal / totalmins);
      // 

    },
    async submitForm(close) {
      const hd = this.data.mehours;
      let totalmins = 0;
      const totalDaysInMinutes = (this.days * hd) * 60;
      totalmins += totalDaysInMinutes;
      totalmins += this.hours * 60;
      totalmins += this.minutes;
      this.$emit("timeSelected", { days: this.days, hours: this.hours, minutes: this.minutes, totalmins, allocated: this.DataIn.minsAllocated, daysNeeded: this.daysNeeded });
      this.$parent.close();
    },
  }
};
</script>
