<template>
  <div class="modal-card">
    <header class="modal-card-head" style="padding: 10px;">
      <p class="modal-card-title">Compare Value Propositions</p>
    </header>
    <section class="modal-card-body">
      <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <div v-if="!loading">
        <table>
          <tr>
            <td class="pcol txtbold txth5" v-for="(c, index) in DataIn" :key="index">{{c.title}}</td>
          </tr>
          <tr>
            <td class="pcol" v-for="(c, index) in DataIn" :key="index">{{c.description}}</td>
          </tr>
          <tr>
            <td class="pcol" v-for="(c, index) in DataIn" :key="index">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Painpoints</div>
                <div class="iconbutton" @click="(helppain) ? helppain=false:helppain=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helppain">What pain points are you fixing? </div>
                <b-input placeholder="What pain points are you fixing for them?"
                    v-model.trim="c.textpainpoints" icon-right="plus" icon-right-clickable
                    @icon-right-click="addText('textpainpoints', c.painpoints, c)" v-if="isEdit"
                    @keyup.native.enter="addText('textpainpoints', c.painpoints, c, index);$event.target.select();$event.currentTarget.value=''"
                    @focus="$event.target.select();$event.currentTarget.value=''"
                >
                </b-input>
                <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                  <div class="tsk p3 vlgreybg midgrey m3" v-if="c.painpoints.length === 0">No pain points defined</div>
                  <draggable tag="div" :list="c.painpoints" handle=".handle">
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in c.painpoints" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                      <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                        <div class="iconbutton" @click="removeFrom(ti, c.painpoints)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                        <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="pcol" v-for="(c, index) in DataIn" :key="index">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Educate</div>
                <div class="iconbutton" @click="(helpeducate) ? helpeducate=false:helpeducate=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helpeducate">How are you going to educate this profile? What can you teach them?</div>
                <b-input placeholder="How can you educate them?"
                    v-model="c.texteducate" icon-right="plus" icon-right-clickable
                    @icon-right-click="addText('texteducate', c.educate, c)" v-if="isEdit"
                    @keyup.native.enter="addText('texteducate', c.educate, c, index);$event.target.select();"
                    @focus="$event.target.select()">
                </b-input>
                <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                  <div class="tsk p3 vlgreybg midgrey m3" v-if="c.educate.length === 0">Nothing entered</div>
                  <draggable tag="div" :list="c.educate" handle=".handle">
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in c.educate" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                      <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                        <div class="iconbutton" @click="removeFrom(ti, c.educate)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                        <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="pcol" v-for="(c, index) in DataIn" :key="index">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Entertain</div>
                <div class="iconbutton" @click="(helpentertain) ? helpentertain=false:helpentertain=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helpentertain">How are you going to entertain this profile?</div>
                <b-input placeholder="How can you entertain them?"
                    v-model="c.textentertain" icon-right="plus" icon-right-clickable
                    @icon-right-click="addText('textentertain', c.entertain, c)" v-if="isEdit"
                    @keyup.native.enter="addText('textentertain', c.entertain, c, index);$event.target.select();"
                    @focus="$event.target.select()">
                </b-input>
                <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                  <div class="tsk p3 vlgreybg midgrey m3" v-if="c.entertain.length === 0">Nothing entered</div>
                  <draggable tag="div" :list="c.entertain" handle=".handle">
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in c.entertain" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                      <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                        <div class="iconbutton" @click="removeFrom(ti, c.entertain)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                        <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="pcol" v-for="(c, index) in DataIn" :key="index">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Desire</div>
                <div class="iconbutton" @click="(helpdesire) ? helpdesire=false:helpdesire=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helpdesire">What is this profiles desires or what desire can you envoke in them. This is not the same as painpoints. What we are looking for here is what will make them associate with you, your product or service. What will make them a evangelist or an ambassador.</div>
                <b-input placeholder="How can you envoke desire in them?"
                    v-model="c.textdesire" icon-right="plus" icon-right-clickable
                    @icon-right-click="addText('textdesire', c.desire, c)" v-if="isEdit"
                    @keyup.native.enter="addText('textdesire', c.desire, c, index);$event.target.select();"
                    @focus="$event.target.select()">
                </b-input>
                <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                  <div class="tsk p3 vlgreybg midgrey m3" v-if="c.desire.length === 0">Nothing entered</div>
                  <draggable tag="div" :list="c.desire" handle=".handle">
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in c.desire" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                      <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                        <div class="iconbutton" @click="removeFrom(ti, c.desire)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                        <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="pcol" v-for="(c, index) in DataIn" :key="index">
              <div class="txtbold redbg white roundedtop p7 isFlex">
                  <div class="isFlexGrow">Blockers</div>
                  <div class="iconbutton" @click="(helpblockers) ? helpblockers=false:helpblockers=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
                </div>
                <div class="lgreybg roundedbottom p7">
                  <div class="txtsm mt7 mb11" v-if="helpblockers">What are the maing blockers you will experience? What obstacles do you have to overcome in order for this profile to buy/follow/read/sell what you have to offer?</div>
                  <b-input placeholder="What prevents a decission?"
                      v-model="c.textblockers" icon-right="plus" icon-right-clickable
                      @icon-right-click="addText('textblockers', c.blockers, c)" v-if="isEdit"
                      @keyup.native.enter="addText('textblockers', c.blockers, c, index);$event.target.select();"
                      @focus="$event.target.select()">
                  </b-input>
                  <div v-bind:class="{ 'tsk lgreyborder p3 mt5': isEdit }">
                    <div class="tsk p3 vlgreybg midgrey m3" v-if="c.blockers.length === 0">Nothing entered</div>
                    <draggable tag="div" :list="c.blockers" handle=".handle">
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in c.blockers" :key="ti">
                        <div class="isFlexGrow">{{t.text}}</div>
                        <div class="isFlex" style="min-width: 50px;" v-if="isEdit">
                          <div class="iconbutton" @click="removeFrom(ti, c.blockers)"><font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon></div>
                          <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </div>
            </td>
          </tr>
        </table>
      </div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Close
        </b-button>
        <b-button  @click="(isEdit) ? isEdit=false:isEdit=true" v-if="canEdit">
          Edit
        </b-button>
      </div>
      <div>
        <b-button @click="submitForm(false)">
          Save
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import draggable from "vuedraggable";
export default {
  name: "ModalMapForm",
  mixins: [Utils, Schemas],
  components: {
    draggable
  },
  props: {
    data: Array,
    inEdit: Boolean,
    inDemo: Boolean,
  },
  data() {
    return {
      PeedListStyle: `width: auto`,
      DataIn: this.data,
      Data: [],
      loading: true,
      isEdit: false,
      canEdit: this.inEdit,
      isDemo: this.inDemo,

      textpainpoints: '',
      texteducate: '',
      textentertain: '',
      textdesire: '',
      textblockers: '',

      helppain: false,
      helpeducate: false,
      helpentertain: false,
      helpdesire: false,
      helpblockers: false,
      isEdit: false,
      sub: localStorage.getItem("sub"),
    };
  },
  created() {
    this.DataLoad();
  },
  methods: {
    async DataLoad() {
      this.DataIn.forEach((r) => {
        if (r.sub === this.sub) {
          this.canEdit = true;
        }
        r.textpainpoints = '';
        r.texteducate = '';
        r.textentertain = '';
        r.textdesire = '';
        r.textblockers = '';
      });
      this.loading = false;
    },

    listenChange: function(e) {
      if (e.keyCode === 13) {}
    },

    addText(field, usearr, c, index) {;
      if (c[field] === '') {
        return;
      }
      usearr.push({ text: c[field] });
      c[field] = '';
    },

    removeFrom(index, usearr) {
      usearr.splice(index, 1);
    },

    async submitForm(close) {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      for (const r of this.DataIn) {
        const sr = {
          _id: r._id,
          painpoints: r.painpoints,
          educate: r.educate,
          entertain: r.entertain,
          desire: r.desire,
          blockers: r.blockers,
        }
        await this.DataSend("post", "/peed/updatefew", sr);
      }
      
      
      if (close) {
        this.$parent.close();
      }
    },
  }
};
</script>

<style>
 .pcol {
   width: 300px;
   min-width: 300px;
   padding: 5px;
 } 
</style>