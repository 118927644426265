<template>
  <div class="GoalsOuter">
    <div class="GoalInner">
      <div class="GoalContent">
        <b-loading :active.sync="isLoading" :can-cancel="false" is-full-page></b-loading>
        <div class="GoalColumn" v-if="viewShowMenu">
          <div class="isFlex p7 bluebg9 white">
            <div class="isFlexGrow">Projects</div>
            <div>
              <b-button size="is-small" type="is-danger" @click="goalNew()">New Project</b-button>
            </div>
            <!-- <div class="iconbutton mh11" @click="changeViewEdit()">
              <font-awesome-icon :icon="['fal', 'magic']" size="lg"></font-awesome-icon>
            </div> -->
            <div></div>
          </div>
          <div>
          <b-input placeholder="Search" type="search" icon="search" icon-right="filter" v-model="searchField" @input="doSearch()" ></b-input>
        </div>
          <div class="isFlexGrow scrolly">
            <!-- instructions: start -->
            <div v-if="GoalList.length === 0">
              <div style="padding-left: 250px;" class="red"><font-awesome-icon :icon="['fal', 'arrow-up']" size="lg"></font-awesome-icon></div>
              <div class="p5 txth5" style="text-align: right;">Use <span class="red">New Project</span> to create a project.</div>
              <div style="height: 110px;"></div>
              <div class="isFlex">
                <div class="red pt3"><font-awesome-icon :icon="['fal', 'arrow-left']" size="lg"></font-awesome-icon></div>
                <div class="isFlexGrow pl11 txth5">Or choose a <span class="bluetxt10">Blueprint</span> to help you create you first Project.</div>
              </div>
            </div>
            <!-- instructions: end -->
            <draggable tag="div" :list="GoalList" handle=".handle" @end="sortList($event, GoalList, 'goals')">
              <section v-for="(g, index) in GoalList" :index="index" :key="index">
                <div class="p11 blueborder2bottom whitebg" v-bind:class="{ bluebg1: g.selected }" v-bind:style="g.useStyle">
                  <div class="isFlex isFlexSpace mb11" v-if="g.blueprint">
                    <div v-bind:style="g.useStyle2"></div>
                    <div class="txtsm" v-if="g.blueprint">{{ g.blueprintstatus }}</div>
                  </div>
                  <div @click="(g.showmore) ? g.showmore=false:g.showmore=true" class="isPointer">
                    <div class="txtbold">{{ g.goaltitle }} <span v-if="g.deleted" class="txtsm red"> Archived</span></div>
                  </div>
                  <div class="mt3" v-if="g.showmore">
                    <div class="txtmd">{{ g.description }} <span v-if="g.deleted" class="txtsm red"> Archived</span></div>
                  </div>
                  <div class="txtsm" v-if="g.showmore">{{ g.goaltype }}</div>
                  
                  <div class="isFlex pt9" v-if="g.showmore">
                    <div class="isFlexGrow">
                      <div v-if="g.blueprint">
                        <!-- <b-button size="is-small">Use blueprint</b-button> -->
                      </div>
                      <div v-if="!g.blueprint">
                        <span class="rounded50 txtsm p3 mr3 bluebg2" v-for="tr in g.tbs" :key="tr._id"> {{ tr.user }} </span>
                        <span v-if="g.tbscnt !== 0">+{{ g.tbscnt }}</span>
                      </div>
                    </div>
                    <div class="iconbutton mr5" @click="moveHere(g, index)" v-if="showMove && g.blueprintstatus !== 'Bought'">
                      <font-awesome-icon :icon="['fal', 'arrow-to-bottom']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton mr5 tsk bluebg2 bluetxt11" @click="goalView(g, index)">
                      <font-awesome-icon :icon="['fal', 'eye']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton mr5 tsk bluebg2 bluetxt11" @click="goalOpen(g, index)">
                      <font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbuttontext mr5 p3 bluebg2 tsk bluetxt11 isFlexAlign" @click="doFunc('goaltask', g)" style="max-height: 24px;">
                      <font-awesome-icon :icon="['fal', 'arrow-to-right']" size="sm" ></font-awesome-icon>
                      <span class="txtsm">{{ g.taskcount }}</span>
                    </div>
                    <div class="iconbutton mr5 bluebg2 tsk bluetxt11" @click="mindMapOpen(g)" v-if="g.blueprintstatus !== 'Bought'">
                      <font-awesome-icon :icon="['fal', 'project-diagram']" size="sm"></font-awesome-icon>
                    </div>

                    <div class="iconbutton mr5 tsk bluebg2 bluetxt11" @click="timeLineOpen(g)">
                      <font-awesome-icon :icon="['fal', 'tasks-alt']" size="sm"></font-awesome-icon>
                    </div>
                    
                    <div class="iconbutton handle bluetxt11" v-if="g.blueprintstatus !== 'Bought'">
                      <font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                </div>
              </section>
            </draggable>
          </div>
          <div class="bluebg1">
            <div
              class="tsk whitebg m7" v-for="(inv, index) in Invites" :key="inv._id">
              <div class="txtbold">You have a new invite waiting!</div>
              <div>{{ inv.message }}</div>
              <div class="mv3">
                <b-button size="is-small" class="mr5" @click="inviteReject(inv, index)">Reject</b-button>
                <b-button size="is-small" @click="inviteAccept(inv, index)">Accept</b-button>
              </div>
            </div>
            <!-- invite form; start -->
            <div class="tsk whitebg m7" v-if="inviteForm">
              <b-field label="E-mail">
                <b-input v-model="inviteEmail"></b-input>
              </b-field>
              <b-field label="Code">
                <b-input v-model="inviteCode"></b-input>
              </b-field>
              <div class="buttons">
                <b-button outlined @click="inviteForm = false">Cancel</b-button>
                <b-button type="is-success" outlined @click="intviteCode()">Submit</b-button>
              </div>
            </div>
            <!-- invite form: end -->
            <!-- search: start -->
            <div class="tsk whitebg m7" v-if="showSearch">
              <div>
                <b-button expanded @click="loadChange('/goals/all')">Active Projects</b-button>
                <b-button expanded @click="loadChange('/goals/archived')">Archived Projects</b-button>
                <b-button @click="loadChange('/goals/pending')" v-if="iseditor" expanded>Pending</b-button>
              </div>
            </div>
            <!-- search: end -->
            <div class="isFlex p3">
              <div class="isFlexGrow isFlex">
                <b-tooltip label="Open search options" position="is-right">
                  <div class="iconbutton mr3 bluebg1 bluetxt10 tsk p3" @click="searchToggle()">
                    <font-awesome-icon :icon="['fal', 'search']" size="lg"></font-awesome-icon>
                  </div>
                </b-tooltip>
                <b-tooltip label="Blueprints">
                  <div class="iconbutton mr3 bluebg1 bluetxt10 tsk p3" v-bind:class="{ buttonselected: blueact }" @click="loadChange('/goals/blueprints')">
                    <font-awesome-icon :icon="['fal', 'ruler-triangle']" size="lg"></font-awesome-icon>
                  </div>
                </b-tooltip>
                <b-tooltip label="Toggle the move functionality">
                  <div class="iconbutton mr3 bluebg1 bluetxt10 ml7 tsk p3" @click="moveToggle()">
                    <font-awesome-icon :icon="['fal', 'person-dolly']" size="lg" v-if="moveList.length !== 0"></font-awesome-icon>
                    <font-awesome-icon :icon="['fal', 'person-dolly-empty']" size="lg" v-if="moveList.length === 0"></font-awesome-icon>
                  </div>
                </b-tooltip>
              </div>
              <b-tooltip label="Toggle the accept invites tool">
                <div class="iconbutton mr3 bluebg1 bluetxt10 tsk p3" @click="intviteCodeToggle()">
                  <font-awesome-icon :icon="['fal', 'user-plus']" size="lg"></font-awesome-icon>
                </div>
              </b-tooltip>              
            </div>
          </div>
        </div>
        <!-- <div v-if="Columns.length === 0 && GoalList.length === 0" v-bind:class="{ fade: GoalList.length !== 0 }">
          <img src="imgs/goals1.png" alt="" style="width: auto !important; max-height: 100% !important;" />
        </div> -->
        <!-- task columns start -->
        <div class="GoalColumn" v-for="(col, colindex) in Columns" :key="colindex">
          <div class="isFlex p7 bluebg9 white">
            <div class="isFlexGrow">
              <div class="iconbutton mr7" @click="col.showQuick=false;" v-if="col.showQuick">
                <font-awesome-icon :icon="['fal', 'times']" size="lg"></font-awesome-icon>
              </div>
              <div class="iconbutton mr7" @click="closeColumn(colindex)" v-if="!col.showQuick">
                <font-awesome-icon :icon="['fal', 'times']" size="lg"></font-awesome-icon>
              </div>
            </div>
            <div class="iconbutton mr7" @click="showQuickToggle(col, colindex)" v-if="ActiveGoal.blueprintstatus !== 'Bought'">
              <font-awesome-icon :icon="['fal', 'rabbit-fast']" size="lg"></font-awesome-icon>
            </div>
            <div class="iconbutton" @click="taskNew(col, colindex)" v-if="ActiveGoal.blueprintstatus !== 'Bought'">
              <font-awesome-icon :icon="['fal', 'plus-circle']" size="lg"></font-awesome-icon>
            </div>
            <!-- <div class="iconbutton mh11" @click="changeViewEdit()">
              <font-awesome-icon :icon="['fal', 'magic']" size="lg"></font-awesome-icon>
            </div> -->
            <div></div>
          </div>
          <div class="bluebg9 p5" v-if="col.showQuick">
            <b-input type="textarea" v-model="col.quickText" rows="2"></b-input>
            <div class="p5 white">

              <div class="isFlex isFlexAlign">
                <div class="iconbutton" @click="col.showQuickTime=true" v-if="!col.showQuickTime">
                  <font-awesome-icon :icon="['fal', 'caret-right']" size="sm"></font-awesome-icon>
                </div>
                <div class="iconbutton" @click="col.showQuickTime=false" v-if="col.showQuickTime">
                  <font-awesome-icon :icon="['fal', 'caret-down']" size="sm" ></font-awesome-icon>
                </div>
                <div class="isFlexGrow txtbold">Time</div>
              </div>

              <div class="ml11" v-if="col.showQuickTime">
                <div class="isFlex mb3">
                  <div style="width: 90px;">Days:</div><div style="width: 150px;"><b-numberinput size="is-small" min="0" v-model="col.estdays"></b-numberinput></div>
                </div>
                <div class="isFlex mb3">
                  <div style="width: 90px;">Hours:</div><div style="width: 150px;"><b-numberinput size="is-small" min="0" v-model="col.esthours"></b-numberinput></div>
                </div>
                <div class="isFlex mb7">
                  <div style="width: 90px;">Minutes:</div><div style="width: 150px;"><b-numberinput size="is-small" min="0" step="15" max="45" v-model="col.estminutes"></b-numberinput></div>
                </div>
              </div>

              <div class="isFlex isFlexAlign">
                <div class="iconbutton" @click="col.showQuickTeam=true" v-if="!col.showQuickTeam">
                  <font-awesome-icon :icon="['fal', 'caret-right']" size="sm"></font-awesome-icon>
                </div>
                <div class="iconbutton" @click="col.showQuickTeam=false" v-if="col.showQuickTeam">
                  <font-awesome-icon :icon="['fal', 'caret-down']" size="sm" ></font-awesome-icon>
                </div>
                <div class="isFlexGrow txtbold">Team</div>
              </div>
              
              <div v-if="col.showQuickTeam" class="pl11">
                <div class="isFlex" v-for="(av, index) in GoalMembers" :key="av._id">
                  <div class="isFlexGrow">{{ av.username }}</div>
                  <div><b-checkbox v-model="av.worker" type="is-light" size="is-small"></b-checkbox></div>
                </div>
              </div>

              <div class="mt5" v-if="ActiveGoal.people.length !== 0">
                <div class="isFlex isFlexAlign">
                  <div class="iconbutton" @click="col.showQuickRoles=true" v-if="!col.showQuickRoles">
                    <font-awesome-icon :icon="['fal', 'caret-right']" size="sm"></font-awesome-icon>
                  </div>
                  <div class="iconbutton" @click="col.showQuickRoles=false" v-if="col.showQuickRoles">
                    <font-awesome-icon :icon="['fal', 'caret-down']" size="sm" ></font-awesome-icon>
                  </div>
                  <div class="isFlexGrow txtbold">Needed Roles</div>
                </div>

                <div class="pl11" v-if="col.showQuickRoles">
                  <div class="isFlex" v-for="(pr, pindx) in ActiveGoal.people" :key="pindx">
                    <div class="isFlexGrow">{{ pr.role }}</div>
                    <div><b-checkbox v-model="pr.edit" type="is-light" size="is-small"></b-checkbox></div>
                  </div>
                </div>
              </div>
            </div>
            <div style="height: 5px;"></div>
            <b-button expanded @click="quickAdd(col, colindex)">Add task</b-button>
          </div>
          <div class="isFlexGrow scrolly" id="tasklistCards">
            <div class="tsk bluebg1 m5" v-if="col.list.length === 0">No active sub tasks found</div>
            <draggable tag="div" :list="col.list" handle=".handle" @end="sortListTasks($event, col.list, 'tasks')">
              <section v-for="(t, cindex) in col.list" :key="t._id">
                <div class="p11 blueborder2bottom whitebg" v-bind:class="{ bluebg1: t.selected }">
                  <div class="isFlex isFlexSpace">
                    <div v-bind:style="ActiveGoal.useStyle2"></div>
                    <!-- <div class="txtsm">{{ t.estdays }}d {{ t.esthours }}h {{ t.estminutes }}m</div> -->
                    <span class="txtsm isPointer"	@click="(t.editWorkflow) ? t.editWorkflow=false:t.editWorkflow=true">{{ t.workflow }}</span>
                  </div>
                  <template v-if="canEdit && t.editTitle">
                    <div class="mt5 mb5">
                      <b-select v-model="t.workflow" size="is-small" expanded @input="taskQuickSave(t, colindex, cindex)">
                        <optgroup label="Workflow">
                          <option :value="wf.value" v-for="(wf, wi) in ActiveGoal.workflow" :key="wi">{{wf.label}}</option>
                        </optgroup>
                        <optgroup label="Functions">
                          <option value="Archive">Archive</option>
                          <option value="Restore">Restore</option>
                          <option value="Delete">Delete</option>
                        </optgroup>
                        
                      </b-select>
                    </div>
                  </template>
                  <template v-if="!canEdit">
                    <div><span class="txtbold">{{ t.tasktext }}</span> <span v-if="t.deleted" class="txtsm red"> Archived</span></div>
                    <div class="txtsm">{{ t.taskdescription }}</div>
                  </template>
                  <template v-if="canEdit && !t.editTitle">
                    <div @click="t.editTitle = true"><span class="txtbold">{{ t.tasktext }}</span> <span v-if="t.deleted" class="txtsm red"> Archived</span></div>
                    <div class="txtsm">{{ t.taskdescription }}</div>
                  </template>
                  <template v-if="canEdit && t.editTitle">
                    <div><b-input v-model="t.tasktext" size="is-small" type="textarea" rows="1" @keyup.native.enter="taskQuickSave(t, colindex, cindex);" icon-right="times" icon-right-clickable @icon-right-click="t.editTitle=false">></b-input></div>
                    <div><b-input v-model="t.taskdescription" size="is-small" type="textarea" rows="2" placeholder="Description" @keyup.native.enter="taskQuickSave(t, colindex, cindex);"></b-input></div>
                  </template>
                  
                  <div class="isFlex pt9">
                    <div class="isFlexGrow">
                      <span class="rounded50 txtsm p3 mr3 bluebg1" v-for="(tr, tindex) in t.tbs" :key="tindex">
                        {{ tr.user }}
                      </span>
                      <span v-if="t.tbscnt !== 0">+{{ t.tbscnt }}</span>
                      
                    </div>
                    <div class="pt5" v-if="showMove && ActiveGoal.blueprintstatus !== 'Bought'">
                      <b-checkbox size="is-small" v-model="t.checked" @input="moveAddToList(t, colindex, cindex)"></b-checkbox>
                    </div>
                    <div class="iconbutton mr5" @click="moveHereTask(t, colindex)" v-if="showMove && ActiveGoal.blueprintstatus !== 'Bought'">
                      <font-awesome-icon :icon="['fal', 'arrow-to-bottom']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton mr5 bluebg2 tsk bluetxt11" @click="taskOpen(t, colindex, cindex)">
                      <font-awesome-icon :icon="['fal', 'eye']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbuttontext mr5 p3 bluebg2 tsk bluetxt11 isFlexAlign" @click="doFunc('task', t, colindex)">
                      <font-awesome-icon :icon="['fal', 'arrow-to-right']" size="sm" ></font-awesome-icon>
                      <span class="txtsm">{{ t.taskcount }}</span>
                    </div>
                    <div class="iconbutton handle bluetxt11" v-if="ActiveGoal.blueprintstatus !== 'Bought'">
                      <font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                </div>
              </section>
            </draggable>
          </div>
          <!-- task foot: start -->
          <div class="bluebg1">
            <!-- search: start -->
            <div class="tsk whitebg m7" v-if="showTaskSearch">
              <div>
                <b-button expanded @click="tasksColOpen(col.from, col.parent, colindex, false)">Active Tasks</b-button>
                <b-button expanded @click="tasksColOpen(col.from, col.parent, colindex, true)">Archived Tasks</b-button>
              </div>
            </div>
            <!-- search: end -->
            <!-- task footer buttons; start -->
            <div class="isFlex p3">
              <div class="iconbutton mr3 bluebg1 bluetxt10 tsk p3" @click="searchTaskToggle()">
                <font-awesome-icon :icon="['fal', 'search']" size="lg"></font-awesome-icon>
              </div>
            </div>
            <!-- task footer buttons; end -->
          </div>
          <!-- task foot: end -->
        </div>
        <!-- task columns end -->
        <!-- goal view: start -->
        <template v-if="viewShow">
          <div class="GoalColumn" style="width: 400px; position: relative">
            <div class="lgreyborderbottom whitebg isFlex">
              <div class="iconbutton mt5" @click="(viewShowMenu) ? viewShowMenu=false:viewShowMenu=true">
                <font-awesome-icon :icon="['fal', 'bars']" size="lg"></font-awesome-icon>
              </div>
              <div class="isFlexGrow txtc p7">Info</div>
            </div>
            <b-loading :active.sync="isLoadingInfo" :can-cancel="false" :is-full-page="false"></b-loading>
            <div class="isFlexGrow scrolly" v-if="viewShowInfo">
              <template v-if="showVideo">
                <div style="background-color:">
                  <video-player  class="video-player-box"
                    ref="videoPlayer"
                    :options="playerOptions"
                    :playsinline="true"
                    customEventName="customstatechangedeventname">
                  </video-player>
                </div>
              </template>
              <template v-else>
                <div v-if="Goal.blueprintimage !== ''">
                  <img :src="Goal.blueprintimage" alt="">
                </div>
              </template>

              <div class="p11">
                <div class="txth3 mt9 isFlexGrow">{{ Goal.goaltitle }}</div>

                <div class="mb7">{{Goal.description}}</div>

                <div class="label">What?</div>
                <div class="mb11" style="margin-left: 22px;">
                  <ul style="list-style: circle;">
                    <li v-for="(wl, wi) in Goal.whatlist" :key="wi">{{ wl.text }}</li>
                  </ul>
                </div>
                <div class="label">Why?</div>
                <div class="mb11" style="margin-left: 22px;">
                  <ul style="list-style: circle;">
                    <li v-for="(wy, wyi) in Goal.whylist" :key="wyi">{{ wy.text }}</li>
                  </ul>
                </div>

                <div style="height: 20px;"></div>
                <div class="txth4 mt11">Team</div>
                <div class="isFlex blueborder1bottom" v-for="ga in Goal.availableto" :key="ga._id" >
                <div class="p3 isFlexGrow">
                  <div>
                    <strong>{{ ga.username }}</strong>
                    <span v-if="ga.permission === 'Owner'"> (Owner)</span>
                  </div>
                  <div>{{ ga.email }}</div>
                </div>
              </div>
              </div>

            </div>
          </div>
          <div class="GoalColumn" style="position: relative">
            <div class="txtc p7 lgreyborderbottom whitebg">Outline</div>
            <b-loading :active.sync="isLoadingTasks" :can-cancel="false" :is-full-page="false"></b-loading>
            <div class="isFlexGrow scrolly p11" v-if="viewShowTasks">
              <ul class="ul">
                <taskitem v-for="task in TreeData" :key="task._id" :item="task" :Active="ActTask"></taskitem>
              </ul>
            </div>
          </div>
          <div class="GoalColumn" style="position: relative">
            <div class="isFlex">
              <div class="w50p txtc p7 lgreyborderbottom isPointer" :class="{ redborderbottom: showPeed }" @click="showTrees=false;showPeed=true;">PEED</div>
              <div class="w50p txtc p7 lgreyborderbottom isPointer" :class="{ redborderbottom: showTrees }" @click="showTrees=true;showPeed=false;">Mind Maps</div>
            </div>
            <b-loading :active.sync="isLoadingPeed" :can-cancel="false" :is-full-page="false"></b-loading>
            <div class="isFlexGrow scrolly p11 mb11" v-if="showPeed && viewShowPeed">
              <div v-if="peedList.length === 0">No PEED profiles defined</div>

              <div class="tsk p7 mb7 isPointer vlgreybg" v-for="(n, pi) in peedList" :index="pi" :key="n._id">
                <div class="txtc" @click="openPeed(n, pi)">
                  <font-awesome-icon :icon="['fal', 'id-card']" size="2x" v-if="n.type === 'Profile'"></font-awesome-icon>
                  <font-awesome-icon :icon="['fal', 'box']" size="2x" v-if="n.type === 'Product'"></font-awesome-icon>
                  <font-awesome-icon :icon="['fal', 'question']" size="2x" v-if="n.type === 'Other'"></font-awesome-icon>
                  <font-awesome-icon :icon="['fal', 'concierge-bell']" size="2x" v-if="n.type === 'Service'"></font-awesome-icon>
                  <font-awesome-icon :icon="['fal', 'newspaper']" size="2x" v-if="n.type === 'Content'"></font-awesome-icon>
                  <font-awesome-icon :icon="['fal', 'bullhorn']" size="2x" v-if="n.type === 'Advert'"></font-awesome-icon>
                </div>
                <div>
                  <div class="txtc txtbold mb3 mt5" @click="openPeed(n, pi)">{{ n.title }}</div>
                  <div class="txtc txtsm mb3 mt5" @click="openPeed(n, pi)">{{ n.description }}</div>
                  <div class="txtc mb3 mt5"><b-checkbox size="is-small" v-model="n.checked">Compare</b-checkbox></div>
                </div>
              </div>

            </div>
            <div v-if="showPeed && viewShowPeed" class="lgreybg p3">
              <b-button size="is-small" expanded @click="comparePeed">Compare</b-button>
            </div>
            <div class="isFlexGrow scrolly p11" v-if="showTrees && viewShowPeed">
              <!-- trees: start -->
              <div v-if="Trees.length === 0">No trees planted</div>
              <div class="tsk p7 mb7 isPointer vlgreybg" v-for="(g, ti) in Trees"  :index="ti" :key="g._id">
                <div @click="openMindMap(g)">
                  <div class="txtbold txtlg txtc pb5">{{ g.title }}</div>
                  <div class="txtsm txtc pb5" v-if="g.goaltitle !== ''" :style="`color: ${g.goalcolor}`">{{ g.goaltitle }}</div>
                  <div class="txtsm txtc pb5" v-else>Not associated with a goal</div>
                  <div class="txtsm txtc pb5">{{ g.oneliner }}</div>
                  <div class="txtsm txtc pb11">{{ g.updatedAt | displaydate("ddd do MMM YY @ HH:mm") }}</div>
                </div>
              </div>
              <!-- trees: end -->
            </div>
          </div>
          <div class="GoalColumn" style="position: relative">
            <div class="txtc p7 lgreyborderbottom whitebg">Comments</div>
            <b-loading :active.sync="isLoadingComments" :can-cancel="false" :is-full-page="false"></b-loading>
            <div class="isFlexGrow scrolly p11" v-if="viewShowComments">
              <div v-if="Goal.comments.length === 0">No comments</div>
              <div class="tsk p7 m7 isFlex vlgreybg" v-for="(n, nid) in Goal.comments" :key="nid">
                <div class="ml11 isFlexGrow">
                  <div class="txtsm">{{ n.from }} Date: {{ n.date | displaydateFull }} </div>
                  <div class="tskhead">{{ n.comment }}</div>
                  <div class="isFlex isFlexEnd" v-if="n.fromid === sub">
                    <b-button size="is-small" @click="commentDelete(nid)">Delete</b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="p11 lgreybg" v-if="viewShowComments">
              <div><b-input v-model="newComment" maxlength="300" rows="2" type="textarea" placeholder="Post a comment"></b-input></div>
              <div class="buttons">
                <b-button expanded @click="commentAdd">Post Comment</b-button>
              </div>
            </div>
          </div>
          <div class="GoalColumn" style="position: relative">
            <div class="txtc p7 lgreyborderbottom whitebg">Resources</div>
            <b-loading :active.sync="isLoadingResources" :can-cancel="false" :is-full-page="false"></b-loading>
            <div class="isFlexGrow scrolly p11" v-if="viewShowResources">
              <!-- links: start -->
              <div v-if="Goal.links.length === 0">No Links Defined</div>
              <section class="tsk vlgreybg mb11 lgreyborder" v-for="(gl, li) in Goal.links" :index="li" :key="li">
                <div class="isFlex pt5 pb5">
                  <div class="isFlexGrow pl3 pr5">
                    <div class="txtbold">{{ gl.title }}</div>
                    <div>{{ gl.description }}</div>
                  </div>
                  <div v-if="gl.type === 'Youtube video'">
                    <b-button icon-right="play" size="is-small" @click="playVideo(gl, 'video/youtube')">Play</b-button>
                  </div>
                </div>
              </section>
              <!-- links: end -->

              <!-- uploads: start -->
              <div class="p11" v-if="Goal.files.length === 0">You don't have any files uploaded.</div>
              <section class="tsk vlgreybg mb11 lgreyborder" v-for="(gf, index) in Goal.files" :key="gf.key">
                <div class="isFlex mb3 isFlexAlign">
                  <div class="txtbold isFlexGrow">{{ gf.title }}</div>
                  <div v-if="gf.mimetype === 'video/mp4'" class="mt11 mb11">
                    <b-button icon-right="play"  size="is-small" @click="playVideo(gf, 'video/mp4')">Play</b-button>
                  </div>
                </div>
                <div class="mb3">{{ gf.description }}</div>
                <div v-if="gf.mimetype === 'image/png' || gf.mimetype === 'image/jpg' || gf.mimetype === 'image/jpeg'">
                  <a :href="gf.url" target="_blank"><img :src="gf.url" /></a>
                </div>
              </section>
              <!-- uploads: end -->

            </div>
          </div>
        </template>
        <!-- goal view: end -->
      </div>
    </div>
  </div>
</template>

<script>
// import store from "../store";
// import ObjectID from 'bson-objectid';
// import { EventBus } from "../event-bus.js";
import draggable from "vuedraggable";
import debounce from "lodash/debounce";
import { Utils } from "@/mixins/utils.js";
import { dbFunctions } from "@/mixins/dbMixins.js";
import ModalGoals from "@/modals/ModalGoals.vue";
import ModalTask from "@/modals/ModalTask.vue";
import ModalTimeLine from "@/modals/ModalTimeLine.vue";
import ModalMindMap from "@/modals/ModalMindMap.vue";
import ModalTree from "@/modals/ModalTree.vue";
import ModalVideo from "@/modals/ModalVideo.vue";
import ModalPeed from "@/modals/ModalPeed.vue";
import dataLearn from '../../public/learn.json'
import ModalPeedCompare from '../modals/ModalPeedCompare.vue';
import taskitem from "@/components/task-item.vue";
export default {
  mixins: [Utils, dbFunctions],
  name: "goals",
  components: {
    draggable, taskitem
  },
  data() {
    return {
      load: "/goals/all",
      showSearch: false,
      showTaskSearch: false,
      showProjects: true,
      showWho: true,
      showMove: false,
      columns: [],
      GoalList: [],
      GoalActive: {},
      GoalActiveIndex: -1,
      TaslActive: {},
      Invites: [],
      inviteForm: false,
      inviteEmail: "",
      inviteCode: "",
      Goal: {},
      ActiveGoal: {},
      ActiveTask: {},
      ActTask: '',
      ActiveCol: -1,
      Columns: [],
      iseditor: false,
      moveList: [],
      quickText: "",
      timeTasks: [],
      GoalMembers: [],
      GoalRoles: [],
      blueact: false,

      searchField: '',

      covervideo: '',
      showVideo: false,

      playerOptions: {
        // videojs options
        width: '400',
        autoplay: false,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: 'video/mp4',
          src: ''
        }],
        poster: this.poster,
      },

      viewShow: false,
      viewShowMenu: true,
      viewShowInfo: false,
      viewShowTasks: false,
      viewShowPeed: false,
      viewShowComments: false,
      viewShowResources: false,

      showPeed: false,
      showPeed: true,
      showTrees: false,

      isDemo: false,
      canEdit: false,

      isLoading: false,

      isLoadingInfo: false,
      isLoadingTasks: false,
      isLoadingPeed: false,
      isLoadingComments: false,
      isLoadingResources: false,

      peedList: [],
      peedCompList: [],

      newComment: '',

      sub: localStorage.getItem("sub"),
    };
  },

  created() {
    this.iseditor = this.editorCheck();
    this.goalsGet();
    this.invitesGet();
    // this.treeOpen("5d565b633486a9adcf3fc1a0");
    // this.timeLineOpen("5d565b633486a9adcf3fc1a0");
  },

  methods: {
    loadChange(newpath) {
      this.Columns = [];
      if (newpath === '/goals/blueprints') {
        if (this.blueact) {
          this.blueact = false;
          newpath = '/goals/all';
        } else {
          this.blueact = true;
        }
      } else {
        this.blueact = false;
      }
      this.load = newpath;
      this.goalsGet();
    },
    searchToggle() {
      if (this.showSearch) {
        this.showSearch = false;
      } else {
        this.showSearch = true;
      }
    },

    doSearch: debounce(function() {
      this.goalsGet();
    }, 2000),

    async goalsGet() {
      this.isLoading = true;

      const search = {
          searchField: this.searchField,
        }
      const GoalsGet = await this.DataSend('post', this.load, search, 'rows', false);
      for (let gl = 0; gl < GoalsGet.length; gl++) {
        const gr = GoalsGet[gl];
        gr.useStyle = {
          borderLeft: `10px solid ${gr.goalcolor}`
        };
        gr.useStyle2 = {
          backgroundColor: `${gr.goalcolor}`,
          width: "100px",
          height: "3px"
        };
        const tmd = this.goalProcessAvailable(gr.availableto);
        gr.tbs = tmd.rettbs;
        gr.tbscnt = tmd.rettbscnt;
        gr.showmore = false;
      }
      this.GoalList = GoalsGet;
      this.isLoading = false;
    },

    goalNew() {
      this.goalOpen({ _id: "new" }, -1);
    },

    changeselected(row) {
      for (const gl of this.GoalList) {
        gl.selected = false;
      }
      row.selected = true;
      this.isDemo = row.demo;
      if (this.isDemo) {
        this.canEdit = true;
      }
      if (row.sub === this.sub) {
        this.canEdit = true;
        this.isDemo = false;
      }
    },

    async goalOpen(goal, index) {
      this.changeselected(goal);
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = true;
      }
      this.GoalActive = goal;
      this.GoalActiveIndex = index;
      const that = this;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalGoals,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: that.GoalActive, // this is the row data that gets sent to the modal
          callBus: "Modal_Goals_Goal" // name of the listner for the data coming in from the modal
        },
        events: {
          goalUpdated: value => {
            if (value.type === "delete") {
              if (this.ActiveGoal._id === that.GoalActive._id) {
                that.Columns = [];
              }

              that.GoalList.splice(that.GoalActiveIndex, 1);
            } else if (value.type === "new") {
              value.data.useStyle = {
                backgroundColor: `${value.data.goalcolor}`,
                width: "50px",
                height: "3px"
              };
              const tmd = this.goalProcessAvailable(value.data.availableto);
              value.data.tbs = tmd.rettbs;
              value.data.tbscnt = tmd.rettbscnt;
              const newindex = this.GoalList.push(value.data);
              that.GoalActiveIndex = newindex - 1;
              that.GoalActive = value.data;
            } else {
              that.GoalActive.goaltitle = value.data.goaltitle;
              that.GoalActive.taskcount = value.data.taskcount;
              that.GoalActive.availableto = value.data.availableto;
              that.GoalActive.useStyle = {
                borderLeft: `10px solid ${gr.goalcolor}`
              };
              const tmd = this.goalProcessAvailable(value.data.availableto);
              that.GoalActive.tbs = tmd.rettbs;
              that.GoalActive.tbscnt = tmd.rettbscnt;
              that.GoalActive.deleted = value.data.deleted;
            }
          }
        }
      });
    },

    async goalView(goal,index) {
      this.changeselected(goal);
      this.viewShow = true;
      this.isLoadingInfo = true;
      this.isLoadingTasks = true;
      this.isLoadingPeed = true;
      this.isLoadingComments = true;
      this.isLoadingResources = true;

      this.viewShowInfo = false;
      this.viewShowTasks = false;
      this.viewShowPeed = false;
      this.viewShowComments = false;
      this.viewShowResources = false;

      this.Columns = [];
      this.Goal = {};
      this.TreeData = [];
      this.showVideo = false;
      const ingoal = await this.axiosget(`/goal/${goal._id}`);
      this.Goal = ingoal.rows;

      if (this.Goal.blueprintstatus !== 'Bought') {
        const sub = localStorage.getItem('sub');
        for (const u of this.Goal.availableto) {
          if (u._id === sub) {
            if(u.permission === 'Owner' || u.permission === 'Admin') {
              this.canEdit = true;
            }
          }
        }
        this.isDemo = this.Goal.demo;
        if (this.isDemo) {
          this.canEdit = true;
        }
        if (this.Goal.sub === this.sub) {
          this.canEdit = true;
          this.isDemo = false;
        }
      }
      if( this.Goal.blueprintvideo !== '') {
        this.playerOptions.sources[0].src = this.Goal.blueprintvideo;
        this.showVideo = true;
      }
      this.viewShowInfo = true;
      this.isLoadingInfo = false;

      const data = {
        parentid: this.Goal._id,
        parenttype: "goal",
        deleted: false,
        workflowtype: "none",
        workflow: "Completed",
        sortby: "childorder",
      };
      const tasks = await this.DataSend("post", "/taskstree", data, "", false);
      this.TreeData = tasks.rows;
      this.viewShowTasks = true;
      this.isLoadingTasks = false;

      this.peedList = await this.DataGet(`/peedbygoal/${goal._id}`);
      for (const p of this.peedList) {
        p.checked = false;
        p.cntpain = p.painpoints.length;
        p.cntedu = p.educate.length;
        p.cntent = p.entertain.length;
        p.cntdes = p.desire.length;
        p.cntblock = p.blockers.length;
      }
      this.viewShowPeed = true;
      this.isLoadingPeed = false;

      const tdata = { goalid: this.Goal._id };
      const getB = await this.DataGet(`/mindmapbygoal/${this.Goal._id}`);
      this.Trees = getB;

      this.viewShowComments = true;
      this.viewShowResources = true;
      this.isLoadingComments = false;
      this.isLoadingResources = false;

      this.viewShow = true;
      this.isLoading = false;
    },

    playVideo(gf, type) {
      let useFull = true;
      let useCanCancel = false;
        const inw = document.body.clientWidth;
        if (inw > 750) { useFull = false; useCanCancel = true; }
      	this.modal = this.$buefy.modal.open({
          parent: this,
          component: ModalVideo,
          hasModalCard: true,
          fullScreen: useFull,
          canCancel: useCanCancel,
          width: 700,
          props: {
            title: gf.title,
            video: gf.url,
            type
          },
          events: {
            dataFromModal: value => {}
          }
        });
    },

    openPeed(p, index) {
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) { useFull = false; useCanCancel = true; }
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalPeed,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          peedid: p._id,
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },

    comparePeed() {
      const compareArr = [];
      for (const p of this.peedList) {
        if (p.checked) {
          compareArr.push(p);
        }
      }
      if (compareArr.length === 0) {
        this.$buefy.snackbar.open({ message: "Please make an selection first", position: "is-bottom" });
        return;
      }
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalPeedCompare,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: compareArr,
          inEdit: this.canEdit,
          inDemo: this.isDemo,
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },

    async openMindMap(row) {
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalMindMap,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: row,
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },

    async commentAdd() {
      if( this.newComment === '') {
        return;
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const sub = localStorage.getItem("sub");
      const first = localStorage.getItem("firstname");
      const last = localStorage.getItem("lastname");
      const nr = {
        fromid: sub,
        from: `${first} ${last}`,
        comment: this.newComment,
        date: new Date(),
      }
      this.Goal.comments.push(nr);
      const data = {
        _id: this.Goal._id,
        comments: this.Goal.comments,
      }
      const upgoal = await this.DataSend('post', '/goal/updatefew', data, "", false);
      this.newComment = '';
    },

    commentDelete(i) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this comment?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.Goal.comments.splice(i, 1);
          const data = {
            _id: this.Goal._id,
            comments: this.Goal.comments,
          }
          const upgoal = await this.DataSend('post', '/goal/updatefew', data, "", false);
        }
      });
    },

    goalProcessAvailable(availableto) {
      const tbs = [];
      let tbscntin = 0;
      for (const gb of availableto) {
        tbscntin += 1;
        if (tbs.length < 3) {
          if (gb.username) {
            const ints = this.getInitials(gb.username);
            const newgb = { _id: gb._id, user: ints };
            tbs.push(newgb);
          }
        }
      }
      let rettbs = tbs;
      let rettbscnt = 0;
      const tbscntout = tbscntin - tbs.length;
      if (tbscntout > 0) {
        rettbscnt = tbscntout;
      }
      return { rettbs, rettbscnt };
    },

    async sortList(evt, list, model) {
      const newlist = [];
      for (let i = 0; i < list.length; i++) {
        const p = list[i];
        const newitem = {
          _id: p._id,
          childorder: i
        };
        newlist.push(newitem);
      }
      await this.DataSend("post", "/goals/sort", newlist, "", false);
    },

    async sortListTasks(evt, list, model) {
      const newlist = [];
      for (let i = 0; i < list.length; i++) {
        const p = list[i];
        const newitem = {
          _id: p._id,
          childorder: i
        };
        newlist.push(newitem);
      }
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      await this.DataSend("post", "/tasks/sort", newlist, "", false);
    },

    async doFunc(func, data, col) {
      this.viewShow = false;
      // reset columns
      let nwcol = {};
      let activecol = -1;
      let newcolno = -1;
      switch (func) {
        case "task":
          this.Columns.splice(col + 1, 999);
          newcolno = col + 1;
          this.ActiveTask = data;
          nwcol = {
            from: "task",
            parent: data._id,
            list: [],
            showQuick: false,
            showQuickTeam: false,
            showQuickTime: false,
            showQuickRoles: false,
            quickText: "",
            estdays: 0,
            esthours: 0,
            estminutes: 15
          };
          if (this.Columns[newcolno]) {
            activecol = newcolno;
          } else {
            this.crcol = this.Columns.push(nwcol);
            activecol = this.crcol - 1;
          }
          // eslint-disable-next-line no-case-declarations
          this.tasksColOpen("task", data._id, activecol, false);
          this.changeTaskSelected(data, col);
          break;
        default:
          this.GoalMembers = [];
          this.changeselected(data);
          this.ActiveGoal = data;
          this.isDemo = this.ActiveGoal.demo;
          if (this.ActiveGoal.sub === this.sub) {
            this.isDemo = false;
          }
          this.ActiveTask = { _id: "none" };
          const user = localStorage.getItem('sub');
          for (const av of data.availableto) {
            const nr = { 
              _id: av._id, 
              username: av.username, 
              all: false,
              worker: false,
              resources: false,
              review: false,
              approve: false,
              complete: false,
              completed: false,
            }
            if (user === av._id){
              nr = { 
                _id: av._id, 
                username: av.username, 
                all: true,
                worker: true,
                resources: true,
                review: true,
                approve: true,
                complete: true,
                completed: true,
              }
            }
            
            this.GoalMembers.push(nr);
          }
          this.Columns = [];
          // eslint-disable-next-line no-case-declarations
          nwcol = {
            from: "goal",
            parent: data._id,
            list: [],
            showQuick: false,
            showQuickTeam: false,
            showQuickTime: false,
            showQuickRoles: false,
            quickText: "",
            estdays: 0,
            esthours: 0,
            estminutes: 15,
          };
          this.crcol = this.Columns.push(nwcol);
          // eslint-disable-next-line no-case-declarations
          activecol = this.crcol - 1;
          this.tasksColOpen("goal", this.ActiveGoal._id, activecol, false);
          break;
      }
    },

    closeColumn(col) {
      this.Columns.splice(col, 999);
    },

    checkAll(row) {
      if(row.all) {
        row.worker = true;
        row.resources = true;
        row.review = true;
        row.approve = true;
        row.complete = true;
      } else {
        row.worker = false;
        row.resources = false;
        row.review = false;
        row.approve = false;
        row.complete = false;
      }
    },

    // tasks cols: start
    async tasksColOpen(from, parent, col, archived) {
      const data = {};
      if (from === "goal") {
        data.parenttype = "goal";
        data.deleted = archived;
        data.parentid = parent;
        data.workflowtype = "ne";
        data.workflow = "Completed";
      }
      if (from === "task") {
        data.parenttype = "task";
        data.deleted = archived;
        data.parentid = parent;
        data.workflowtype = "ne";
        data.workflow = "Completed";
      }
      const intasks = await this.DataSend("post", "/tasksbyparent", data, "rows");

      for (let i = 0; i < intasks.length; i++) {
        const tbs = [];
        let tbscntin = 0;
        const rw = intasks[i];
        for (const gb of rw.assignedto) {
          tbscntin += 1;
          if (tbs.length < 3) {
            if (gb.username) {
              const ints = this.getInitials(gb.username);
              const newgb = { _id: gb._id, user: ints };
              tbs.push(newgb);
            }
          }
        }
        rw.tbs = tbs;
        rw.tbscnt = 0;
        const tbscntout = tbscntin - tbs.length;
        if (tbscntout > 0) {
          rw.tbscnt = tbscntout;
        }
        rw.editTitle = false;
        rw.editDesc = false;
        rw.editWorkflow = false;
      }

      this.Columns[col].list = intasks;
    },

    changeTaskSelected(row, col) {
      for (const tl of this.Columns[col].list) {
        tl.selected = false;
      }
      row.selected = true;
    },

    showQuickToggle(col, colindex) {
      if (col.showQuick) {
        col.showQuick = false;
      } else {
        col.showQuick = true;
      }
    },
    async quickAdd(col, colindex) {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      if (col.quickText !== "") {
        const newtask = {
          _id: "new",
          goalid: this.ActiveGoal._id,
          goalcolor: this.ActiveGoal.goalcolor,
          goalname: this.ActiveGoal.goaltitle,
          parentid: col.parent,
          tasktext: col.quickText,
          estdays: col.estdays,
          esthours: col.esthours,
          estminutes: col.estminutes,
          assignedto: [],
          people: [],
        };
        for (const av of this.GoalMembers) {
          let addrow = false;
          if (av.worker) { addrow = true; }
          if (av.resources) { addrow = true; }
          if (av.review) { addrow = true; }
          if (av.approve) { addrow = true; }
          if (av.complete) { addrow = true; }
          if (addrow) {
            newtask.assignedto.push(av);
          }
        }

        for (const pr of this.ActiveGoal.people) {
          let addrow = false;
          if (pr.edit) { addrow = true; }
          if (addrow) {
            newtask.people.push(pr);
          }
        }
        
        if (col.from === "goal") {
          newtask.parentid = "";
        }
        const uptask = await this.DataSend("put", "/task", newtask);
        const tmd = this.goalProcessAvailable(uptask.rows.assignedto);
        uptask.rows.tbs = tmd.rettbs;
        uptask.rows.tbscnt = tmd.rettbscnt;
        uptask.rows.selected = true;
        col.list.push(uptask.rows);
        col.quickText = "";
        col.showQuick = false;
        this.scrollToBot('tasklistCards', 0);
      }
    },

    taskNew(col, index) {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      const newtask = {
        _id: "new",
        goalid: this.ActiveGoal._id,
        goalcolor: this.ActiveGoal.goalcolor,
        goalname: this.ActiveGoal.goaltitle,
        parentid: col.parent,
      };
      this.taskOpen(newtask, index);
    },

    
    
    async taskOpen(task, index, tindex) {
      this.changeTaskSelected(task, index);
      this.ActiveCol = index;
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) {
        useFull = false;
        useCanCancel = true;
      }
      this.TaskActive = task;
      const that = this;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: that.TaskActive, // this is the row data that gets sent to the modal
          callBus: "Modal_Goals_Task" // name of the listner for the data coming in from the modal
        },
        events: {
          taskUpdated: value => {
            if (value.type === "delete") {
              // delete functions
              this.Columns[index].list.splice(tindex, 0);
            } else if (value.type === "new") {
              // new here
              const tmd = this.goalProcessAvailable(value.data.assignedto);
              value.data.tbs = tmd.rettbs;
              value.data.tbscnt = tmd.rettbscnt;
              this.Columns[this.ActiveCol].list.push(value.data);
            } else {
              that.TaskActive.tasktext = value.data.tasktext;
              that.TaskActive.taskdescription = value.data.taskdescription;
              that.TaskActive.taskcount = value.data.taskcount;
              that.TaskActive.assignedto = value.data.assignedto;
              const tmd = this.goalProcessAvailable(value.data.assignedto);
              that.TaskActive.tbs = tmd.rettbs;
              that.TaskActive.tbscnt = tmd.rettbscnt;
              that.TaskActive.deleted = value.data.deleted;
              that.TaskActive.estdays = value.data.estdays;
              that.TaskActive.esthours = value.data.esthours;
              that.TaskActive.estminutes = value.data.estminutes;
            }
            // update all 
            const newarr = [...this.Columns];
            newarr.reverse();
            let actparent = '';
            let actdays = 0;
            let acthours = 0;
            let actminutes = 0;
            for (const rw of newarr) {
              if (rw.list.length !== 0) {
                for (const li of rw.list) {
                  if (li._id === actparent) {
                    li.estdays = actdays;
                    li.esthours = acthours;
                    li.estminutes = actminutes;
                  } else {
                    actdays += li.estdays;
                    acthours += li.esthours;
                    actminutes += li.estminutes;
                  }
                }
                if (actminutes > 45) {
                  const intime = this.time_convert(actminutes);
                  acthours += intime.hours;
                  actminutes = intime.minutes;
                }
                actparent = rw.parent;
              }
            }
            this.ActiveTask.estdays = actdays;
            this.ActiveTask.esthours = acthours;
            this.ActiveTask.estminutes = actminutes;
          }
        }
      });
    },

    time_convert(num) {
      var hours = Math.floor(num / 60);  
      var minutes = num % 60;
      return { hours, minutes } 
    },

    searchTaskToggle() {
      if (this.showTaskSearch) {
        this.showTaskSearch = false;
      } else {
        this.showTaskSearch = true;
      }
    },

    taskQuickSave(row, ci, i) {
      if (row.workflow === 'Archive') {
        this.taskArchive(row, ci, i);
        return;
      }
      if (row.workflow === 'Restore') {
        this.taskRestore(row, ci, i);
        return;
      }
      if (row.workflow === 'Delete') {
        this.taskDelete(row, ci, i);
        return;
      }
      if (row.workflow === 'Completed') {
        row.completed = true;
      } else {
        row.completed = false;
      }
      const tosave = {
        _id: row._id,
        tasktext: row.tasktext.trim(),
        taskdescription: row.taskdescription.trim(),
        workflow: row.workflow,
        completed: row.completed,
      }
      this.DataSend('post', '/task/updatefew', tosave, '', false);
      row.editTitle = false;
      row.editDesc = false;
      row.editWorkflow = false;
    },

    taskArchive(Task, ci, i) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Archive</b> this task and all it's children tasks?",
        confirmText: "Archive",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          Task.deleted = true;
          this.DataDelete(`/task/archive/${Task._id}`, "row");
          Task.editWorkflow = false;
        }
      });
    },
    taskRestore(Task, ci, i) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Restore</b> this task and all it's children tasks?",
        confirmText: "Restore",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          Task.deleted = false;
          this.DataDelete(`/task/restore/${Task._id}`, "row");
          Task.editWorkflow = false;
        }
      });
    },
    taskDelete(Task, ci, i) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>Delete</b> this task and all it's children tasks?",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          if(this.isDemo) {
            this.DataDemo();
            return;
          }
          this.DataDelete(`/task/${Task._id}`, "row");
          // Columns colindex list
          if (this.ActiveTask._id === Task._id) {
            this.Columns.splice(ci+1, 1);
          }
          this.Columns[ci].list.splice(i, 1);
          Task.editWorkflow = false;
        }
      });
    },

    moveToggle() {
      if (this.showMove) {
        this.showMove = false;
      } else {
        this.showMove = true;
      }
    },
    moveAddToList(task, colindex, cindex) {
      if (!task.checked) {
        let removerow = -1;
        for (let i = 0; i < this.moveList.length; i++) {
          const e = this.moveList[i];
          if (task._id === e._id) {
            removerow = i;
          }
        }
        this.moveList.splice(removerow, 1);
      } else {
        task.colindex = colindex;
        task.cindex = cindex;
        this.moveList.push(task);
      }
    },

    async moveHere(goal, index) {
      const newrows = [];
      for (const tl of this.moveList) {
        const nr = {
          _id: tl._id,
          goalid: goal._id,
          goalcolor: goal.goalcolor,
          goalname: goal.goaltitle,
          parentid: ""
        };
        newrows.push(nr);
      }
      const ret = await this.DataSend("post", "/movetask", newrows);
      this.moveList = [];
      this.doFunc("goaltask", goal, index);
    },

    async moveHereTask(task, index) {
      const newrows = [];
      for (const tl of this.moveList) {
        const nr = {
          _id: tl._id,
          goalid: task.goalid,
          goalcolor: task.goalcolor,
          goalname: task.goalname,
          parentid: task._id
        };
        newrows.push(nr);
        if (this.Columns[index + 1]) {
          tl.checked = false;
          this.Columns[index + 1].list.push(tl);
        }
        if (this.Columns[tl.colindex]) {
          let removerow = -1;
          for (let i = 0; i < this.Columns[tl.colindex].list.length; i++) {
            const te = this.Columns[tl.colindex].list[i];
            if (te._id === tl._id) {
              removerow = i;
            }
          }
          if (removerow !== -1) {
            this.Columns[tl.colindex].list.splice(removerow, 1);
          }
        }
      }
      const ret = await this.DataSend("post", "/movetask", newrows);
      this.moveList = [];
      this.doFunc("task", task, index);
    },
    // tasks cols: end

    // invites: start
    async invitesGet() {
      const InvGet = await this.DataGet("/invites");
      this.Invites = InvGet;
    },

    async inviteAccept(row, index) {
      row.status = "Accepted";
      
      const inme = this.GetMeLocal();
      row.userid = inme.sub;
      row.username = inme.subname;
      const accpt = await this.DataSend("post", "/invite/process", row);
      if (index !== -1) {
        this.Invites.splice(index, 1);
      }
      this.goalsGet();
    },

    async intviteCodeToggle() {
      if (this.inviteForm) {
        this.inviteForm = false;
      } else {
        this.inviteForm = true;
      }
    },

    async intviteCode() {
      let haserr = false;
      if (this.inviteEmail === "") {
        this.$buefy.toast.open("Please enter a valid email address");
        haserr = true;
      }
      if (this.inviteCode === "") {
        this.$buefy.toast.open("Please enter a valid code");
        haserr = true;
      }
      if (haserr) {
        return;
      }
      const sendcode = await this.DataSend(
        "post",
        "/invite/code",
        { email: this.inviteEmail, code: this.inviteCode },
        "",
        false
      );
      if (sendcode.status === 200) {
        this.$buefy.toast.open("Invite found. Just processing.");
        this.inviteAccept(sendcode.row, -1);
      } else {
        this.$buefy.dialog.alert(
          "No matching invite against the provided details"
        );
      }
    },

    async inviteReject(row, index) {
      row.status = "Rejected";
      this.DataSend("post", "/invite/process", row);
    },
    // invites: end

    // tree: start

    async treeOpen(goalid) {
      const data = {
        parentid: goalid,
        deleted: false,
        parenttype: "goal",
        workflowtype: "ne",
        workflow: "Completed"
      };
      const intree = await this.DataSend("post", "/taskstree", data);
    },
    // tree: end
    // timeline: start
    async timeLineOpen(row) {
      if(this.isDemo) {
        this.DataDemo();
        return;
      }
      this.Columns = [];
      this.ActiveGoal = row;
      const that = this; 
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTimeLine,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: that.ActiveGoal
        },
        // events: {
        //   fromTimeLine: value => {}
        // }
      });
    },
    // timeline: end
    // mindmap: start
    async mindMapOpen(row) {
      // this.changeselected(row);
      this.Columns = [];
      this.ActiveGoal = row;
      const that = this; 
      const inh = document.body.clientHeight;
      const height = inh -100;
      const data = {
        parentid: row._id,
        parenttype: "goal",
        deleted: false,
        workflowtype: "ne",
        workflow: "Completed"
      };
      
      const tasks = await this.DataSend("post", "/tasksmm", data, "");
      const groot = [
        { 
          name: row.goaltitle, 
          goalid: row._id,
          _id: '',
          children: tasks.rows,
          parentid: '',
        }
      ];

      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalTree,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: that.ActiveGoal,
          // mindmap: start
          value: groot,
          // width: 700,
          height: height,
          draggable: true,
          xSpacing: 80,
          ySpacing: 20,
          gps: true,
          usegoal: true,
          usegoalid: row._id,
          usegoalname: row.goaltitle,
          usegoalcolor: row.goalcolor,
          usebus: 'fromGoal'
          // mindmap: end
        },
        events: {
          fromGoal: value => {}
        }
      });
    }
    // mindmap: end
  }
};
</script>

<style>
.GoalsOuter {
  position: relative;
  height: 100%;
}
.GoalInner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  /* display: flex; */
}
.GoalContent {
  width: 2000px;
  height: 100%;
  display: flex;
}
.GoalColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  border-right: 1px solid #efefef;
}
.GoalMenu {
  border: 1px solid red;
  width: 80px;
}
.GoalHeader {
  background-color: bisque;
}
.GoalBody {
  background-color: aliceblue;
  flex-grow: 1;
}
.userInitials {
  background-color: aqua;
  border-radius: 50%;
  font-size: 0.8em;
  padding: 3px;
}
</style>
