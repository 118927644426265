<template>
  <div class="modal-card whitebg">
    <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
    <header class="modal-card-head bluebg11" style="padding: 10px; border-radius: 0px !important;">
      <p class="modal-card-title white">{{ this.Goal.goaltitle }}</p>
    </header>
    <!-- <section class="isFlex p3">
      <div class="pr7 pl7">Days</div>
      <div style="width: 120px;"><b-numberinput size="is-small" v-model="Goal.daystotal" @input="daysChange($event)"></b-numberinput></div>
      <div class="pr7 pl7">Hours/Day</div>
      <div style="width: 120px;"><b-numberinput size="is-small" min="0" max="23" v-model="Goal.dayshours"></b-numberinput></div>
      <div class="pr7 pl7">Minutes/Day</div>
      <div style="width: 120px;"><b-numberinput size="is-small" v-model="Goal.daysminutes" step="15" min="0" max="45"></b-numberinput></div>
      <div class="ml7"><b-button size="is-small">Update</b-button></div>
    </section> -->
    <section class="isFlex p3">
      <div class="isFlex mr7 tsk bluebg1" style="min-height: 48px; max-height: 48px; width: 270px;">
        <div class="mr7 mt5 txtbold">Start Date:</div>
        <div class="mr7 mt5 isFlexGrow" v-if="!showDateChange">{{ DueDate | displaydateFull }}</div>
        <div v-if="showDateChange" class=" isFlexGrow">
          <b-datepicker v-model="NewDate" placeholder="Select new date..." icon="calendar" @input="changeDate()" style="width: 140px;">
            <!-- <button class="button is-primary is-small" @click="NewDate = new Date()">
              <b-icon icon="calendar-day"></b-icon>
              <span>Today</span>
            </button>
            <button class="button is-danger is-small" @click="NewDate = null">
              <b-icon icon="times"></b-icon>
              <span>Clear</span>
            </button> -->
          </b-datepicker>
        </div>
        <div class="iconbutton tsk bluebg4 white ml3 mr3 mt3" v-bind:class="{ bluebg10: showDateChange }" @click="changeDateShowHide()" v-if="canEdit">
          <font-awesome-icon :icon="['fal', 'calendar-day']" size="sm"></font-awesome-icon>
        </div>
      </div>

      <div class="isFlex tsk bluebg1 mr7" style="max-height: 48px;" v-if="canEdit">
        <div class="mr7 mt5">+-Days</div>
        <div style="width: 50px;"><b-input v-model="adjustdays"></b-input></div>
        <div class="iconbutton tsk bluebg10 white ml3 mr3 mt3" @click="daysAdd('add')">
          <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
        </div>
        <div class="iconbutton tsk bluebg10 white mr3 mt3" @click="daysMinus">
          <font-awesome-icon :icon="['fal', 'minus']" size="sm"></font-awesome-icon>
        </div>
      </div>

      <div class="isFlex tsk bluebg1 mr7 pt11" style="max-height: 48px;" v-if="canEdit">
        <div><b-numberinput v-model="increment" min="0" max="60" step="15" size="is-small"></b-numberinput></div>
      </div>

      <div class="isFlex tsk bluebg1 mr7" style="max-height: 48px;">
        <div class="field mr11">
          <b-switch v-model="showWorkflow">Workflow</b-switch>
        </div>
        <div class="field mr11">
          <b-switch v-model="showPeople">Resources</b-switch>
        </div>
        <div class="field mr11">
          <b-switch v-model="showAssigned">Assigned</b-switch>
        </div>
      </div>
      <div class="isFlex tsk bluebg1 mr7" style="max-height: 48px;" v-if="canEdit">
        <div class="tsk bluebg10 white mr3 isPointer" @click="flattenData">
          Create Report
        </div>
        <div v-if="this.flatData.length !== 0">
          <download-csv
          class = "tsk bluebg10 white isPointer"
          :data = "flatData"
          name = "data.csv">
          Download CSV
          </download-csv>
        </div>
      </div>
    </section>

    <section class="modal-card-body" style="position: relative;" id="modalbody">
      <!-- timeline: start -->
      <div v-if="!isFullLoading">
        <!-- Left list: start -->
        <div style="position: absolute; top: 20px; padding-left: 20px; left: 0px; background-color: white;" id="menudiv">
          <table>
            <thead>
              <tr>
                <th style="width: 440px; height: 54px;" class="bluebg1">
                  <div class="isFlex p5">
                    <div class="isFlexGrow">Tasks</div>
                    <div class="iconbutton tsk bluebg11 white mr7" @click="taskNew(null, -1, 'none')" v-if="canEdit">
                      <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                </th>
                <th class="tbtime">Needed</th>
                <th class="tbtime">
                  <div class="iconbutton tsk white mr3 bluebg4" v-bind:class="{ bluebg10: shift }" @click="shiftGoalOpen()" v-if="canEdit">
                    <font-awesome-icon :icon="['fal', 'arrow-alt-right']" size="sm"></font-awesome-icon>
                  </div>
                </th>
              </tr>
            </thead>
            <draggable tag="tbody" :list="timeTasks" handle=".handle" @end="sortList($event, timeTasks)">
              <tr v-for="(t, index) in timeTasks" :index="index" :key="index" @click="selectRow(t)">
                <td class="tbltitle" v-bind:style="t.usestyle">
                  <div class="isFlex">
                    <div class="isFlexGrow">
                      <div>{{ t.tasktext }}</div>
                      <div class="txtsm ml7 isFlex" v-if="showWorkflow">
                        <div style="min-width: 25px;">WF:</div>
                        <div class="isFlexGrow">{{ t.workflow }}</div>
                      </div>
                      <div class="txtsm ml7 isFlex" v-if="showPeople">
                        <div style="min-width: 25px;">RS:</div>
                        <div class="isFlexGrow"><span v-for="(res, ri) in t.people" :key="ri">&bull; {{ res.role }} </span></div>
                      </div>
                      <div class="txtsm ml7 isFlex" v-if="showAssigned">
                        <div style="min-width: 25px;">TM:</div>
                        <div class="isFlexGrow"><span v-for="(tm, ri) in t.assignedto" :key="ri">&bull; {{ tm.username }} </span></div>
                      </div>
                    </div>
                    <div class="isFlex" style="min-width: 110px; max-width: 110px; height: 28px;">
                      <div class="iconbutton tsk bluebg10 white mr3" @click="taskOpen(t, index)">
                        <font-awesome-icon :icon="['fal', 'eye']" size="sm"></font-awesome-icon>
                      </div>
                      <!-- <div class="iconbutton tsk bluebg10 white mr3" @click="taskNew(t, index)">
                        <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon> taskNewShow(t, index)
                      </div> -->
                      <div class="iconbutton tsk bluebg10 white mr3" @click="taskNew(t, index, 'sibling')" v-if="canEdit">
                        <font-awesome-icon :icon="['fal', 'arrow-to-bottom']" size="sm"></font-awesome-icon>
                      </div>
                      <div class="iconbutton tsk bluebg10 white mr3" @click="taskNew(t, index, 'child')" v-if="canEdit">
                        <font-awesome-icon :icon="['fal', 'child']" size="sm"></font-awesome-icon>
                      </div>
                      <div class="iconbutton handle tsk bluebg10 white" v-if="canEdit">
                        <font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon>
                      </div>
                    </div>
                  </div>
                </td>
                <!-- Needed -->
                <td class="tbtime txtsm">
                  <div v-if="t.taskcount === 0">{{ t.allocatedmins }}/{{ t.totalmins }}</div>
                </td>

                <td class="tbtime">
                  <div class="isFlex" v-if="t.taskcount === 0 && canEdit">
                    <div class="iconbutton tsk white mr3 bluebg4" @click="removeTaskDays(t, true)">
                      <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton tsk white mr3 bluebg4" v-bind:class="{ bluebg10: t.wizard1 }" @click="wizard1Open(t)">
                      <font-awesome-icon :icon="['fal', 'magic']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton tsk white mr3 bluebg4" v-bind:class="{ bluebg10: t.shift }" @click="shiftOpen(t, index)">
                      <font-awesome-icon :icon="['fal', 'arrow-alt-down']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
              </td>
              </tr>
              </draggable>
              <thead>
                <tr>
                  <th style="width: 440px; height: 54px;" class="bluebg1">
                    <div class="isFlex p5">
                      <div class="isFlexGrow">Tasks</div>
                      <div class="iconbutton tsk bluebg11 white mr7" @click="taskNew(null, -1, 'none')" v-if="canEdit">
                        <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
                      </div>
                    </div>
                  </th>
                  <th class="tbtime">Needed</th>
                  <th class="tbtime">
                    <div class="iconbutton tsk white mr3 bluebg4" v-bind:class="{ bluebg10: shift }" @click="shiftGoalOpen()" v-if="canEdit">
                      <font-awesome-icon :icon="['fal', 'arrow-alt-right']" size="sm"></font-awesome-icon>
                    </div>
                  </th>
                </tr>
              </thead>
          </table>
        </div>
        <!-- Left list: end -->
        <table>

          <thead>
            <tr>
              <th style="width: 440px; height: 54px;">
                <div class="isFlex">
                  <div class="isFlexGrow">Task</div>
                  <div class="iconbutton tsk bluebg1" @click="taskNew(null, -1)">
                    <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
                  </div>
                </div>
              </th>
              <!-- <th style="min-width: 90px; text-align: center;">Who</th> -->
              <th style="min-width: 100px;">Needed</th>

              <th style="min-width: 100px;">
                <div class="iconbutton tsk white mr3 bluebg4" v-if="layerHide" v-bind:class="{ bluebg10: shift }" @click="shiftGoalOpen()">
                  <font-awesome-icon :icon="['fal', 'arrow-alt-right']" size="sm"></font-awesome-icon>
                </div>
              </th>

              <th class="tbtime txtsm" v-for="(tday, index) in Goal.goalDays" :key="index">
                <div class="isFlex">
                  <div class="iconbutton tsk bluebg8 white" @click="shiftGoalDo(tday, index)" v-if="shift">
                    <font-awesome-icon :icon="['fal', 'arrow-alt-down']" size="sm"></font-awesome-icon>
                  </div>
                  <div class="isFlexGrow">{{ tday.dayMC }}<br/>{{ tday.dateout }}<br/>{{ tday.minutes }}</div>
                </div>
              </th>
            </tr>
          </thead>

          <draggable tag="tbody" :list="timeTasks" handle=".handle">
            <tr v-for="(t, index) in timeTasks" :index="index" :key="index" @click="selectRow(t)">

              <td class="tbltitle" v-bind:style="t.usestyle">
                <div class="isFlex">
                  <div class="isFlexGrow">
                    <div>{{ t.tasktext }}</div>
                    <div class="txtsm ml7 isFlex" v-if="showWorkflow">
                      <div style="min-width: 25px;">WF:</div>
                      <div class="isFlexGrow">{{ t.workflow }}</div>
                    </div>
                    <div class="txtsm ml7 isFlex" v-if="showPeople">
                      <div style="min-width: 25px;">RS:</div>
                      <div class="isFlexGrow"><span v-for="(res, ri) in t.people" :key="ri">&bull; {{ res.role }} </span></div>
                    </div>
                    <div class="txtsm ml7 isFlex" v-if="showAssigned">
                      <div style="min-width: 25px;">TM:</div>
                      <div class="isFlexGrow"><span v-for="(tm, ri) in t.assignedto" :key="ri">&bull; {{ tm.username }} </span></div>
                    </div>
                  </div>
                  <div style="min-width: 110px; max-width: 110px; height: 28px;"></div>
                  <div class="isFlex" v-if="layerHide">
                    <div class="iconbutton tsk bluebg10 white mr3" @click="taskOpen(t, index)">
                      <font-awesome-icon :icon="['fal', 'eye']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton tsk bluebg10 white" @click="taskNew(t, index)">
                      <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                </div>
              </td>

              <!-- <td class="tbwho">
                <span class="rounded50 txtsm p3 mr3 bluebg2" v-for="(tr, tindex) in t.tbs" :key="tindex">
                  {{ tr.user }}
                </span>
                <span v-if="t.tbscnt !== 0">+{{ t.tbscnt }}</span>
                <br/>{{ t.estdays }}d {{ t.esthours }}h {{ t.estminutes }}m
              </td> -->

              <td class="tbtime txtsm">
                <div v-if="t.taskcount === 0 && layerHide">{{ t.allocatedmins }}/{{ t.totalmins }}</div>
              </td>
              
              <td class="tbtime">
                <div class="isFlex" v-if="t.taskcount === 0 && layerHide">
                    <div class="iconbutton tsk white mr3 bluebg4" @click="removeTaskDays(t, true)">
                      <font-awesome-icon :icon="['fal', 'trash']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton tsk white mr3 bluebg4" v-bind:class="{ bluebg10: t.wizard1 }" @click="wizard1Open(t)">
                      <font-awesome-icon :icon="['fal', 'magic']" size="sm"></font-awesome-icon>
                    </div>
                    <div class="iconbutton tsk white mr3 bluebg4" v-bind:class="{ bluebg10: t.shift }" @click="shiftOpen(t, index)">
                      <font-awesome-icon :icon="['fal', 'arrow-alt-down']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
              </td>

              <td class="tbfunction" v-for="(day, index) in t.taskDays" :key="index" v-bind:class="{ tbdayselected: day.allocated !== 0 }">
                <div class="isFlex isFlexAlign isFlexJustify" style="min-height: 28px;">
                  <div v-if="day.shift">
                    <div class="iconbutton tsk bluebg8 white" @click="shiftDo(t, day, index)">
                      <font-awesome-icon :icon="['fal', 'arrow-alt-down']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                  <div v-if="day.wizard1">
                    <div class="iconbutton tsk bluebg8 white" @click="wizard1(t, day, index)">
                      <font-awesome-icon :icon="['fal', 'arrow-alt-right']" size="sm"></font-awesome-icon>
                    </div>
                  </div>
                  <div v-if="(t.taskcount === 0 || day.allocated !== 0) && !day.shift && !day.wizard1">
                    <div class="isFlex">
                      <div class="iconbutton bluebg1 tbday p0" v-bind:class="{ tbdayselected: day.allocated !== 0 }" @click="dayMinus(t, day, true)">
                        <font-awesome-icon :icon="['fal', 'times']" size="sm"></font-awesome-icon>
                      </div>
                      <div class="iconbutton bluebg1 tbday" v-bind:class="{ tbdayselected: day.allocated !== 0 }" @click="dayMinus(t, day)">
                        <font-awesome-icon :icon="['fal', 'minus']" size="sm"></font-awesome-icon>
                      </div>

                      <div class="txtc bluebg1 tbday" v-bind:class="{ tbdayselected: day.allocated !== 0 }" @click="dayModal(t, day, day.allocated)" style="width: 30px; ">{{ day.allocated / 60 }}</div>

                      <div class="iconbutton bluebg1 tbday" v-bind:class="{ tbdayselected: day.allocated !== 0 }" @click="dayPlus(t, day)">
                        <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
                      </div>
                      <div class="iconbutton bluebg1 tbday" v-bind:class="{ tbdayselected: day.allocated !== 0 }" @click="dayPlus(t, day, dailymins)">
                        <font-awesome-icon :icon="['fal', 'calendar-day']" size="sm"></font-awesome-icon>
                      </div>
                    </div>
                    <div v-if="layerHide" class="txtsm txtc" v-bind:class="{ bluetxt1: day.allocated !== 0, bluetxt5: day.allocated === 0 }">{{ day.workflow }}</div>
                  </div>
                  <!-- <div v-if="day.allocated === 0" style="padding-top: 4px;">
                    <div class="iconbutton tsk" style="margin: auto;" @click="dayPlus(t, day)">
                      <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
                    </div>
                  </div> -->
                </div>
              </td>
            </tr>
          </draggable>
          <thead>
            <tr>
              <th style="width: 440px; height: 54px;">
                <div class="isFlex">
                  <div class="isFlexGrow">Task</div>
                  <div class="iconbutton tsk bluebg1" @click="taskNew(null, -1)">
                    <font-awesome-icon :icon="['fal', 'plus']" size="sm"></font-awesome-icon>
                  </div>
                </div>
              </th>
              <!-- <th style="min-width: 90px; text-align: center;">Who</th> -->
              <th style="min-width: 100px;">Needed</th>

              <th style="min-width: 100px;">
                <div class="iconbutton tsk white mr3 bluebg4" v-if="layerHide" v-bind:class="{ bluebg10: shift }" @click="shiftGoalOpen()">
                  <font-awesome-icon :icon="['fal', 'arrow-alt-right']" size="sm"></font-awesome-icon>
                </div>
              </th>

              <th class="tbtime txtsm" v-for="(tday, index) in Goal.goalDays" :key="index">
                <div class="isFlex">
                  <div class="iconbutton tsk bluebg8 white" @click="shiftGoalDo(tday, index)" v-if="shift">
                    <font-awesome-icon :icon="['fal', 'arrow-alt-down']" size="sm"></font-awesome-icon>
                  </div>
                  <div class="isFlexGrow">{{ tday.dayMC }}<br/>{{ tday.dateout }}<br/>{{ tday.minutes }}</div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <!-- timeline: end -->
    </section>
    <footer class="modal-card-foot" style="padding: 10px;">
      <button class="button" type="button" @click="$parent.close()">
        Close
      </button>
      <button type="button" @click="reload()" class="button is-primary">
        Reload
      </button>
      <button type="button" @click="goalSave()" class="button is-primary">
        Save
      </button>
    </footer>
    <div v-if="showMoveMenu" style="position: fixed; left: 0; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.8); z-index: 1000;">
      <div style="display: flex; width: 100%; height: 100%; flex-direction: column; justify-content: flex-end; align-items: center;">
        <div class="roundedtop p11 tskshadowdark whitebg">
          <div style="text-align: center;">Move as: </div>
          <div style="width: 200px; padding-bottom: 7px;"><b-button @click="moveAs('sibling')" expanded>Sibling of Above</b-button></div>
          <div style="width: 200px; padding-bottom: 15px;"><b-button @click="moveAs('child')" expanded>Change to child of Above</b-button></div>
          <div style="width: 200px; padding-bottom: 7px;"><b-button @click="moveAs('level')" expanded>Keep on same leverl</b-button></div>
        </div>
      </div>
    </div>
    <div v-if="showAddMenu" style="position: fixed; left: 0; top: 0; left: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.8); z-index: 1000;">
      <div style="display: flex; width: 100%; height: 100%; flex-direction: column; justify-content: flex-end; align-items: center;">
        <div class="roundedtop p11 tskshadowdark whitebg">
          <div style="text-align: center;">Add as: </div>
          <div style="width: 200px; padding-bottom: 7px;"><b-button @click="taskNew(addTask, addTaskIndex, 'sibling')" expanded>Sibling</b-button></div>
          <div style="width: 200px; padding-bottom: 15px;"><b-button @click="taskNew(addTask, addTaskIndex, 'child')" expanded>Child</b-button></div>
          <div style="width: 200px; padding-bottom: 15px;"><b-button @click="showAddMenu = false;" expanded>Cancel</b-button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import draggable from "vuedraggable";
import ModalTime from "@/modals/ModalTime.vue";
import ModalTask from "@/modals/ModalTask.vue";
import array from "lodash/array";

export default {
  name: "ModalTimeLine",
  mixins: [Utils, Schemas],
  components: {
    draggable
  },
  props: {
    data: Object,
    callBus: String
  },
  data() {
    return {
      GoalIn: this.data,
      Goal: {},
      timeTasks: [],
      calcError: true,
      monday: false,
      mondayhours: 0,
      mondayminutes: 0,
      tuesday: false,
      tuesdayhours: 0,
      tuesdayminutes: 0,
      wednesday: false,
      wednesdayhours: 0,
      wednesdayminutes: 0,
      thursday: false,
      thursdayhours: 0,
      thursdayminutes: 0,
      friday: false,
      fridayhours: 0,
      fridayminutes: 0,
      saturday: false,
      saturdayhours: 0,
      saturdayminutes: 0,
      sunday: false,
      sundayhours: 0,
      sundayminutes: 0,

      showDateChange: false,
      showWorkflow: false,
      showPeople: false,
      showAssigned: false,

      DueDate: null,
      NewDate: null,
      TotalMinutes: 0,
      minsRemain: 0,
      
      TreeData: [],

      TaskActive: {},
      taskTotals: 0,
      taskCount: 0,

      onDays: [],

      adjustdays: 1,

      shift: false,
      shiftIndex: 0,

      Me: {},

      layerHide: false,

      taskAddShow: true,

      showMoveMenu: false,
      showAddMenu: false,

      addTask: {},
      addTaskIndex: -1,

      movePrev: {},
      moveNext: {},

      doReload: false,
      isLoading: true,
      isFullLoading: true,

      lastScrollT: 0,
      lastScrollL: 0,

      flatData: [],
      flatResources: [],

      exportFields: ['level', 'tasktext', 'workflow', 'goalname', 'people'],
      fieldasday: false,

      canEdit: false,

      increment: 60,

      timer: null, 

      dailymins: 480,
    };
  },
  created() {
    this.GoalLoad();
    setTimeout(() => {
      const el = document.getElementById('modalbody');
      el.addEventListener('scroll', this.handleScroll);
    }, 1000);
  },
  methods: {

    reload() {
      const el = document.getElementById('modalbody');
      let nl = el.scrollLeft;
      let nt = el.scrollTop;
      this.lastScrollL = nl;
      this.lastScrollT = nt;
      this.GoalLoad();
    },

    async GoalLoad() {
      this.isLoading = true;
      this.Me = await this.DataGet('/me');
      this.dailymins = this.Me.dayhours * 60;
      const ingoal = await this.DataGet(`/goal/${this.GoalIn._id}`);
      this.Goal = {};
      this.timeTasks = [];
      this.onDays = [];
      if (ingoal.monday) { this.calcError = false; this.monday =true; this.mondayminutes = ((ingoal.mondaysettings.hours * 60) + ingoal.mondaysettings.minutes); }
      if (ingoal.tuesday) { this.calcError = false; this.tuesday =true; this.tuesdayminutes = ((ingoal.tuesdaysettings.hours * 60) + ingoal.tuesdaysettings.minutes); }
      if (ingoal.wednesday) { this.calcError = false; this.wednesday =true; this.wednesdayminutes = ((ingoal.wednesdaysettings.hours * 60) + ingoal.wednesdaysettings.minutes); }
      if (ingoal.thursday) { this.calcError = false; this.thursday =true; this.thursdayminutes = ((ingoal.thursdaysettings.hours * 60) + ingoal.thursdaysettings.minutes); }
      if (ingoal.friday) { this.calcError = false; this.friday =true; this.fridayminutes = ((ingoal.fridaysettings.hours * 60) + ingoal.fridaysettings.minutes); }
      if (ingoal.saturday) { this.calcError = false; this.saturday =true; this.saturdayminutes = ((ingoal.saturdaysettings.hours * 60) + ingoal.saturdaysettings.minutes); }
      if (ingoal.sunday) { this.calcError = false; this.sunday =true; this.sundayminutes = ((ingoal.sundaysettings.hours * 60) + ingoal.sundaysettings.minutes); }
      if(ingoal.startdate !== '') {
        this.DueDate = new Date(ingoal.startdate);
      } else {
        if (this.DueDate === null) {
          this.DueDate = new Date();
        }
      }
      this.Goal = ingoal;

      const sub = localStorage.getItem('sub');
      for (const u of this.Goal.availableto) {
        if (u._id === sub) {
          if(u.permission === 'Owner' || u.permission === 'Admin') {
            this.canEdit = true;
          }
        }
      }
      if (this.Goal.sub === sub) {
        this.canEdit = true;
      }
      if (this.Goal.blueprintstatus === 'Bought') {
        this.canEdit = false;
      }
      
      if (this.calcError) {
        const dailymins = this.Me.dayhours * 60;
        this.monday =true; this.mondayminutes = dailymins;
        this.tuesday =true; this.tuesdayminutes = dailymins;
        this.wednesday =true; this.wednesdayminutes = dailymins;
        this.thursday =true; this.thursdayminutes = dailymins;
        this.friday =true; this.fridayminutes = dailymins;
        this.calcError = false;
      }
      if (this.Goal.goalDays.length === 0) {
        this.getTasks(false);
      } else {
        this.getTasks(true);
      }
    },

    goalProcessAvailable(availableto) {
      const tbs = [];
      let tbscntin = 0;
      for (const gb of availableto) {
        tbscntin += 1;
        if (tbs.length < 3) {
          if (gb.username) {
            const ints = this.getInitials(gb.username);
            const newgb = { _id: gb._id, user: ints };
            tbs.push(newgb);
          }
        }
      }
      let rettbs = tbs;
      let rettbscnt = 0;
      const tbscntout = tbscntin - tbs.length;
      if (tbscntout > 0) {
        rettbscnt = tbscntout;
      }
      return { rettbs, rettbscnt };
    },

    getNextIndex(index, useparent) {
      const totaltasks = this.timeTasks.length;
      let useindex = index;
      for (let i = index + 1; i < this.timeTasks.length; i++) {
        const e = this.timeTasks[i];
        if (e.parentid === useparent) {
          return i;
        }
      }
      return useindex + 1;
    },

    taskNewShow(task, index) {
      this.addTask = task;
      this.addTaskIndex = index;
      this.showAddMenu = true;
    },

    taskNew(task, index, position) {
      let uselevel = 0;
      const newtask = {
        _id: "new",
        goalid: this.Goal._id,
        goalcolor: this.Goal.goalcolor,
        goalname: this.Goal.goaltitle,
        people: [],
        parentid: ''
      };
      if (task !== null) {
        if(position === 'child') {
          newtask.parentid = task._id;
          uselevel = task.level + 1;
          index += 1;
        }
        if(position === 'sibling') {
          newtask.parentid = task.parentid;
          uselevel = task.level;
          index = this.getNextIndex(index, task.parentid);
          // index += 1;
        }
      }
      this.taskOpen(newtask, index, uselevel);
    },

    async taskOpen(task, index, level) {
      let useFull = true;
      let useCanCancel = false;
      const inw = document.body.clientWidth;
      if (inw > 750) { useFull = false; useCanCancel = true; }
      this.TaskActive = task;
      const that = this;
      this.taskmodal = this.$buefy.modal.open({
        parent: this,
        component: ModalTask,
        hasModalCard: true,
        fullScreen: useFull,
        canCancel: useCanCancel,
        width: 700,
        props: {
          data: that.TaskActive,
          callBus: "Modal_Goals_Task",
          edit: this.canEdit
        },
        events: {
          taskUpdated: value => {
            if (value.type === "delete") {
              this.taskTotals -= 1;
              this.timeTasks = [];
              this.isLoading = true;
              this.getTasks(true);
            } else if (value.type === "new") {
              let cnt = level;
              let newmarg = level * 10;
              let usebg = '#e6f6ff';
              if (cnt === 1) { usebg = '#99dcff'; }
              if (cnt === 2) { usebg = '#b3e5ff'; }
              if (cnt === 3) { usebg = '#ccedff'; }
              if (cnt === 4) { usebg = '#e6f6ff'; }
              let usestyle = `padding-left: ${newmarg}px; background-color: ${usebg}`;
              if (cnt === 0) { usestyle = "padding-left: 3px; color: white; background-color: #0096e6;"; }
              const hd = this.Me.dayhours;
              let totalmins = 0;
              const totalDaysInMinutes = (value.data.estdays * hd) * 60;
              totalmins += totalDaysInMinutes;
              totalmins += value.data.esthours * 60;
              totalmins += value.data.estminutes;

              const nr = {
                _id: value.data._id,
                parentid: value.data.parentid,
                level,
                tasktext: value.data.tasktext,
                taskstartdate: value.data.taskstartdate,
                useicon: value.data.useicon,
                workflow: value.data.workflow,
                estblocks: value.data.estblocks,
                estdays: value.data.estdays,
                esthours: value.data.esthours,
                estminutes: value.data.estminutes,
                totalmins,
                allocatedmins: 0,
                goalid: value.data.goalid,
                goalcolor: value.data.goalcolor,
                goalname: value.data.goalname,
                deleted: value.data.deleted,
                ondates: value.data.ondates,
                taskcount: value.data.taskcount,
                people: value.data.people,
                assignedto: value.data.assignedto,
                editWorkFlow: false,
                editBlocks: false,
                usestyle,
                taskDays: [],
                shift: false,
                shiftselect: false,
                selected: false,
                wizard1: false,
                wizard2: false,
              };
              const tmd = this.goalProcessAvailable(value.data.assignedto);
              nr.tbs = tmd.rettbs;
              nr.tbscnt = tmd.rettbscnt;

              for (const g of this.Goal.goalDays) {
                const nd = {
                  dayMC: g.dayMC,
                  day: g.day,
                  db: g.db,
                  dateout: g.dateout,
                  minutes: totalmins,
                  allocated: 0,
                  workflow: '---',
                  shift: false,
                  wizard1: false,
                  wizard2: false,
                  shiftselect: false,
                };
                nr.taskDays.push(nd);
              }
              if(index != -1) {
                this.timeTasks.splice(index, 0, nr);
              } else {
                this.timeTasks.push(nr);
              }
              
              this.showAddMenu = false;
              this.doSort();
              // this.taskTotals += 1;
              // this.timeTasks = [];
              // this.getTasks(true);
            } else {
              let doreload = false;
              if (value.data.estdays !== task.estdays) { doreload = true; }
              if (value.data.esthours !== task.esthours) { doreload = true; }
              if (value.data.estminutes !== task.estminutes) { doreload = true; }
              task.tasktext = value.data.tasktext;
              task.estdays = value.data.estdays;
              task.esthours = value.data.esthours;
              task.esthours = value.data.esthours;
              task.estminutes = value.data.estminutes;
              task.workflow = value.data.workflow;
              task.people = value.data.people;
              task.assignedto = value.data.assignedto;
              const tmd = this.goalProcessAvailable(value.data.assignedto);
              task.tbs = tmd.rettbs;
              task.tbscnt = tmd.rettbscnt;
              this.updateTimesNeeded();
            }
          }
        }
      });
    },

    updateTimesNeeded() {
      for (const task of this.timeTasks) {
        const hd = this.Me.dayhours;
        let totalmins = 0;
        const totalDaysInMinutes = (task.estdays * hd) * 60;
        totalmins += totalDaysInMinutes;
        totalmins += task.esthours * 60;
        totalmins += task.estminutes;
        this.TotalMinutes += totalmins;
        this.minsRemain += totalmins;
        task.totalmins = totalmins;
        task.allocatedmins = 0;
      }
    },

    processChildren(rows, cnt) {
      for (const t of rows) {
        const newmarg = cnt * 10;
        let usebg = '#e6f6ff';
        if (cnt === 1) { usebg = '#99dcff'; }
        if (cnt === 2) { usebg = '#b3e5ff'; }
        if (cnt === 3) { usebg = '#ccedff'; }
        if (cnt === 4) { usebg = '#e6f6ff'; }
        const usestyle = `padding-left: ${newmarg}px; background-color: ${usebg}`;
        const hd = this.Me.dayhours;
        let totalmins = 0;
        const totalDaysInMinutes = (t.estdays * hd) * 60;
        totalmins += totalDaysInMinutes;
        totalmins += t.esthours * 60;
        totalmins += t.estminutes;
        const nr = {
          _id: t._id,
          parentid: t.parentid,
          level: cnt,
          tasktext: t.tasktext,
          taskstartdate: t.taskstartdate,
          useicon: t.useicon,
          workflow: t.workflow,
          estblocks: t.estblocks,
          estdays: t.estdays,
          esthours: t.esthours,
          estminutes: t.estminutes,
          totalmins,
          allocatedmins: 0,
          goalid: t.goalid,
          goalcolor: t.goalcolor,
          goalname: t.goalname,
          deleted: t.deleted,
          ondates: t.ondates,
          taskcount: t.taskcount,
          people: t.people,
          assignedto: t.assignedto,
          editWorkFlow: false,
          editBlocks: false,
          usestyle,
          taskDays: [],
          shift: false,
          shiftselect: false,
          selected: false,
          wizard1: false,
          wizard2: false,
        };
        const tmd = this.goalProcessAvailable(t.assignedto);
        nr.tbs = tmd.rettbs;
        nr.tbscnt = tmd.rettbscnt;
        if (nr.useicon === 'haykal') {
            nr.useicon = 'sparkles';
          }
        this.timeTasks.push(nr);
        this.processChildren(t.steps, cnt + 1);
      }
    },

    async getTasks(force) {
      this.TotalMinutes = 0;
      this.minsRemain = 0;
      this.taskTotals = 0;
      this.tasksProcessing = true;
      const data = {
        parentid: this.Goal._id,
        parenttype: "goal",
        deleted: false,
        workflowtype: "ne",
        workflow: "Completed"
      };
      const tasks = await this.DataSend("post", "/taskstree", data, "");
      this.TreeData = tasks.rows;
      this.taskTotals = tasks.count;
      const tasklist = [];
      for (const t of tasks.rows) {
        const usestyle = "padding-left: 3px; color: white; background-color: #0096e6;";
        const hd = this.Me.dayhours;
        let totalmins = 0;
        const totalDaysInMinutes = (t.estdays * hd) * 60;
        totalmins += totalDaysInMinutes;
        totalmins += t.esthours * 60;
        totalmins += t.estminutes;
        this.TotalMinutes += totalmins;
        this.minsRemain += totalmins;
        
        const nr = {
          _id: t._id,
          parentid: t.parentid,
          level: 0,
          tasktext: t.tasktext,
          taskstartdate: t.taskstartdate,
          useicon: t.useicon,
          workflow: t.workflow,
          estblocks: t.estblocks,
          estdays: t.estdays,
          esthours: t.esthours,
          estminutes: t.estminutes,
          totalmins,
          allocatedmins: 0,
          goalid: t.goalid,
          goalcolor: t.goalcolor,
          goalname: t.goalname,
          deleted: t.deleted,
          ondates: t.ondates,
          taskcount: t.taskcount,
          people: t.people,
          assignedto: t.assignedto,
          editWorkFlow: false,
          editBlocks: false,
          usestyle,
          taskDays: [],
          shift: false,
          shiftselect: false,
          selected: false,
          wizard1: false,
          wizard2: false,
        };
        const tmd = this.goalProcessAvailable(t.assignedto);
        nr.tbs = tmd.rettbs;
        nr.tbscnt = tmd.rettbscnt;

        if (nr.useicon === 'haykal') {
          nr.useicon = 'sparkles';
        }
        this.timeTasks.push(nr);
        this.processChildren(t.steps, 1);
        if (this.timeTasks.length === this.taskTotals) {
          this.processTime(force);
        }
      }
    },

    flattenAddField(field, value) {
      for(const rw of this.flatResources) {
        if(rw[field]) {

        } else {
          rw[field] = value;
        }
      }
    },

    flattenData() {
      this.flatData = [];
      this.flatResources = [];
      let totaldays = 0;
      let totalcost = 0;

      // build headers this.Me.dayhours
      const resources = [];
      const resources2 = [];
      for (const re of this.Goal.people) {
        re.minutes = 0;
        re.hours = 0;
        re.days = 0;
        re.Task = re.role;
        this.flatResources.push(re);
      }
      // build data timeTasks
      let cnt = -1;
      for (const r of this.timeTasks) {
        cnt += 1;
        if (cnt === 0) {
          const nh = {}
        }
        let dashes = '';
        if (r.level !== 0) {
          for (let index = 0; index < r.level; index++) {
            dashes = dashes + '-'
          }
        }
        
        let usetaskname = `${dashes}${r.tasktext}`;
        const nr = {
          Task: usetaskname,
        }
        if (this.showWorkflow) {
          nr.Workflow = r.workflow;
          this.flattenAddField('Workflow', r.workflow);
        }
        if (this.showPeople) {
          let plist = '';
          for (const p of r.people) { plist = plist + ' -' + p.role; } 
          nr.Resource = plist;
          this.flattenAddField('Resource', '');
        }
        if (this.showAssigned) {
          let alist = '';
          for (const p of r.assignedto) { alist = alist + ' -' + p.username; } nr.Assignedto = alist; this.flattenAddField('Assignedto', '');
        }
        nr['Days'] = 0;
        this.flattenAddField('Days', 0);
        nr['Resources'] = 0;
        this.flattenAddField('Resources', 0);
        nr['Cost'] = 0;
        this.flattenAddField('Cost', 0);
        for (const d of r.taskDays) {
          let outstring = '';
          this.flattenAddField(d.db, 0);
          if (d.allocated === 0) {
            outstring = '';
          } else {
            nr['Days'] += 1;
            totaldays += 1;
            outstring = (d.allocated / 60) / this.Me.dayhours;
            for (const p of r.people) {
              const pfound = array.findLastIndex(this.flatResources, { 'role': p.role });
              this.flatResources[pfound].days += 1;
              this.flatResources[pfound].Days += 1;
              this.flatResources[pfound][d.db] += 1;
              nr['Resources'] += 1;
              this.flatResources[pfound].hours += (d.allocated / 60);
              this.flatResources[pfound].minutes += d.allocated;
              if ((this.flatResources[pfound].day * 1) !== 0) {
                const ncost = this.flatResources[pfound].day * 1
                nr['Cost'] += this.flatResources[pfound].day * 1;
                this.flatResources[pfound].Cost += ncost;
                totalcost += this.flatResources[pfound].day * 1;
              } else {
                let cost = (Math.ceil(d.allocated / 60) * (this.flatResources[pfound].hour * 1));
                nr['Cost'] += cost;
                this.flatResources[pfound].Cost = nr['Cost'];
                totalcost += cost;
              }
            }

          }
          nr[d.db] = outstring;
        }

        this.flatData.push(nr);
      }
      // total row
      this.flatData.push({ Task: 'Totals', Days: totaldays, Resources: '', Cost: totalcost });
      this.flatData.push({ Task: ''});
      this.flatData.push({ Task: 'Resources'});
      for (const f of this.flatResources) {
        const newr = {...f};
        delete newr.type;
        delete newr.role;
        delete newr.edit;
        delete newr.day;
        delete newr.hour;
        delete newr.minutes;
        delete newr.hours;
        delete newr.days;
        this.flatData.push(newr);
      }
    },

    selectRow(row,) {
      for (const rw of this.timeTasks) {
        rw.selected = false;
      }
      row.selected = true;
    },

    async sortList(evt, list) {
      if(evt.newIndex != 0) {
        this.movePrev = this.timeTasks[evt.newIndex-1];
        this.moveNext = this.timeTasks[evt.newIndex];
        if (this.movePrev.parentid !== this.moveNext.parentid) {
          this.doReload = true;
        }
        this.showMoveMenu = true;
      } else {
        this.doSort();
      }
      // for (const t of timeTasks) {
        
      // }
      // await this.DataSend("post", "/goals/sort", newlist);
    },

    async moveAs(method) {
      if(method === 'sibling') {
        this.moveNext.parentid = this.movePrev.parentid;
        this.moveNext.level = this.movePrev.level;
        this.moveNext.usestyle = this.movePrev.usestyle;
      } 
      if(method === 'child') {
        this.moveNext.parentid = this.movePrev._id;
        this.moveNext.level = this.movePrev.level + 1;
        this.moveNext.usestyle = this.movePrev.usestyle;
      }
      if(method === 'level') {
        
      }
      
      this.showMoveMenu = false;
      this.doSort();
      // if(this.doReload) {
      //   this.isLoading = true;
      //   this.GoalLoad();
      // }
      this.isLoading = true;
      this.GoalLoad();
    },

    async doSort() {
      const newlist = [];
      for (let i = 0; i < this.timeTasks.length; i++) {
        const p = this.timeTasks[i];
        const newitem = {
          _id: p._id,
          taskorder: i,
          parentid: p.parentid
        };
        newlist.push(newitem);
      }
      const sort = await this.DataSend("post", "/tasks/sorttask", newlist);
    },

    handleScroll() {
      const el = document.getElementById('modalbody');
      let nl = el.scrollLeft;
      let nt = el.scrollTop;
      const mn = document.getElementById('menudiv');
      mn.style.left = (nl)+'px';
    },

    async removeTaskDays(task, save) {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>remove</b> the planning for this task? This action cannot be undone.",
        confirmText: "Remove",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          task.allocatedmins = 0;
          this.removeTasksDo(task, save);
        }
      });

    },

    async removeTasksDo(task, save) {
      for (const tdr of task.taskDays) {
        tdr.allocated = 0;
        tdr.workflow = '---';
      }
      task.ondates = [];
      if(save) {
        const updstr = { _id: task._id, ondates: task.ondates };
        await this.DataSend('post', '/task/updatefew', updstr);
      }
    },

    async daysAdd(dir, cnt = 1) {
      const lastday = this.Goal.goalDays.length - 1;
      let inday = this.Goal.goalDays[lastday];
      let daycount = this.adjustdays;
      if (dir === 'minus') {
        inday = this.Goal.goalDays[0];
        daycount = this.adjustdays;
      }
      let added = 0;

      for (let i = cnt; i < daycount + 1; i++) {
        if (i > 20) {
          return;
        }
        if (added == this.adjustdays) {
           return; 
        }
        let dayIn = this.getDateFull(inday.db, "add", "days", i);
        if (dir === 'minus'){
          dayIn = this.getDateFull(inday.db, "subtract", "days", i);
        }
        const dayofweekMC = dayIn.dayofweektxtshort;
        const dayofweek = dayIn.dayofweektxtlong.toLowerCase();

        if (this[dayofweek]) {
          const mins = this[`${dayofweek}minutes`];
          const dateout = `${dayIn.day}/${dayIn.month}`;
          const nd = {
            dayMC: dayofweekMC,
            day: dayofweek,
            db: dayIn.db,
            dateout,
            minutes: mins,
            allocated: 0,
            workflow: '---',
            shift: false,
            wizard1: false,
            wizard2: false,
            shiftselect: false,
          };
          if (dir === 'add') {
            this.Goal.goalDays.push(nd);
            for (const rt of this.timeTasks) {
              const tday = {...nd};
              rt.taskDays.push(tday);
            }
          } else {
            this.Goal.goalDays.unshift(nd);
            for (const rt of this.timeTasks) {
              const tday = {...nd};
              rt.taskDays.unshift(tday);
            }
          }
          
          added += 1;
        } else {
          if (dir === 'add') {
            daycount += 1;
          } 
        }
      }
      this.goalSave();
    },

    async daysMinus() {
      const fromday = this.Goal.goalDays.length - this.adjustdays;
      this.Goal.goalDays.splice(fromday, this.adjustdays);
      for (const rt of this.timeTasks) {
        rt.taskDays.splice(fromday, this.adjustdays);
      }
      this.goalSave();
    },
    
    changeDateShowHide() {
      if (this.showDateChange) {
        this.showDateChange = false;
      } else {
        this.NewDate = this.DueDate;
        this.showDateChange = true;
      }
    },

    async changeDate() {
      let dayIn = this.getDateFull(this.NewDate, "none", "none", 0);
      const gcnt = this.Goal.goalDays.length;
      this.Goal.goalDays = [];
      const dateout = `${dayIn.day}/${dayIn.month}`;
      this.Goal.goalDays.push({
        dayMC: dayIn.dayofweektxtshort,
        day: dayIn.dayofweektxtlong.toLowerCase(),
        db: dayIn.db,
        dateout,
        minutes: 60,
        allocated: 0,
        workflow: "---",
        shift: false,
        wizard1: false,
        wizard2: false,
        shiftselect: false,
      });

      this.adjustdays = gcnt - 1;
      this.daysAdd('add');

      
      const dtdifin = this.timeDifference(this.NewDate, this.DueDate);
      let cdays = dtdifin.days;
      if (dtdifin.hours > 0) {
        cdays += 1;
      }
      this.changeDateProcess(cdays);
      this.DueDate = this.NewDate;
      const updstr = { _id: this.Goal._id, startdate: this.NewDate };
      await this.DataSend('post', '/goal/updatefew', updstr);
    },

    async changeDateProcess(cdays) {
      const indate = this.NewDate;
      this.adjustdays = cdays;
      for (const t of this.timeTasks) {
        let taskchanged = false;
        const oldrow = [...t.taskDays];

        t.taskDays = [];
        t.ondates = [];
        for (const gd of this.Goal.goalDays) {
          const ndr = {
            dayMC: gd.dayMC,
            day: gd.day,
            db: gd.db,
            dateout: gd.dateout,
            minutes: 60,
            allocated: 0,
            workflow: "---",
            shift: false,
            wizard1: false,
            wizard2: false,
            shiftselect: false,
          }
          t.taskDays.push(ndr);
        }
        let ucnt = -1;
        for (const or of oldrow) {
          ucnt += 1;
          if (or.allocated !== 0) {
            taskchanged = true;
            t.taskDays[ucnt].allocated = or.allocated;
            t.taskDays[ucnt].minutes = or.minutes;

            const nor = {
              dt: t.taskDays[ucnt].db,
              tm: 500,
              mins: or.minutes,
              workflow: t.workflow,
            }

            t.ondates.push(nor);
          }
        }
        if (taskchanged) {
          const updstr = { _id: t._id, ondates: t.ondates };
          await this.DataSend('post', '/task/updatefew', updstr);
        }
        
      }
    },

    wizard1Open(task) {
      if (task.wizard1) {
        task.wizard1 = false;
      } else {
        task.wizard1 = true;
      }
      for (const tdr of task.taskDays) {
        tdr.wizard1 = task.wizard1;
      }
    },

    wizard1(task, day, index) {
      this.modalw = this.$buefy.modal.open({
        parent: this,
        component: ModalTime,
        hasModalCard: true,
        props: {
          data: {
            showDays: false,
            showHours: true,
            showMinutes: false,
            showMinutesFull: true,
            showNeeded: false,
            showDaysNeeded: true,
            minsLimit: 45,
            minsTotal: task.totalmins,
            minsAllocated: task.allocatedmins,
            inmins: 15
          },
        },
        events: {
          timeSelected: async value => {
            const daysNeeded = value.daysNeeded;
            const totalmins = value.totalmins;
            const callength = this.Goal.goalDays.length;
            const sparedays = callength - index;
            if(sparedays < daysNeeded) {
              this.adjustdays = sparedays;
              this.daysAdd('add');
            }
            this.removeTasksDo(task, false);
            for (let i = index; i < daysNeeded+index; i++) {
              const inday = task.taskDays[i];
              this.dayPlusNoSave(task, inday, totalmins);
            }
            for (const tdr of task.taskDays) {
              tdr.wizard1 = false;
            }
            task.allocatedmins = totalmins * daysNeeded;
            task.wizard1 = false;
            const updstr = { _id: task._id, ondates: task.ondates };
            await this.DataSend('post', '/task/updatefew', updstr);
          }
        }
      });
    },

    shiftOpen(task, index){
      if (task.shift) {
        task.shift = false;
      } else {
        task.shift = true;
      }
      for (const tdr of task.taskDays) {
        tdr.shift = task.shift;
      }
    },

    async shiftDo(task, day, index) {
      const newdate = day.db;
      let daysmoved = 0;
      let count = task.ondates.length;
      let arrpos = 0;
      for (let i = 0; i < count; i++) {
        const oc = task.ondates[i];
        const dayIn = this.getDateFull(newdate, "add", "days", i);
        const dayofweek = dayIn.dayofweektxtlong.toLowerCase();
        if (this[dayofweek]){
          task.ondates[arrpos].dt = dayIn.db;
          daysmoved += 1;
          arrpos += 1;
        } else {
          count += 1;
        }
        
      }
      const totaldays = task.taskDays.length;
      const dayno = index;
      let daytotal = dayno + daysmoved;
      let daydif = daytotal - totaldays;
      if (daydif > 0){
        this.adjustdays = daydif;
        this.daysAdd('add');
      }

      for (const tdr of task.taskDays) {
        tdr.allocated = 0;
        tdr.workflow = '---';
        for (const ond of task.ondates) {
          if(tdr.db === ond.dt) {
            tdr.allocated = ond.mins;
            tdr.workflow = ond.workflow;
          }
        }
        tdr.shift = false;
      }
      task.shift = false;
      const updstr = { _id: task._id, ondates: task.ondates };
      await this.DataSend('post', '/task/updatefew', updstr);
    },

    shiftGoalOpen(){
      if (this.shift) {
        this.shift = false;
      } else {
        this.shift = true;
      }
      for (const gi of this.Goal.goalDays) {
        gi.shift = this.shift;
      }
    },

    async shiftGoalDo(day, index) {
      const inday = new Date(day.db);
      const dtdifin = this.timeDifference(inday, this.DueDate);
      let cdays = dtdifin.days;
      if (dtdifin.hours > 0) {
        cdays += 1;
      }
      const callength = this.Goal.goalDays.length;
      let dayindex = 0;
      let firstday = '';
      let firstdayts = 0;
      let firstindex = 0;
      let lastindex = 0;
      for (const tsk of this.timeTasks) {
        let inindx = -1;
        for (const tdays of tsk.taskDays) {
          inindx += 1;
          if (tdays.allocated != 0) {
            const tskdaydt = new Date(tdays.db).valueOf();
            if (firstdayts == 0) {
              firstdayts = tskdaydt;
              firstindex = inindx;
            } else {
              if (tskdaydt < firstdayts) {
                firstdayts = tskdaydt;
                firstindex = inindx;
              }
            }
            lastindex = inindx;
          }
        }
      }
      const tdays = (lastindex - firstindex) + 1;
      firstday = new Date(firstdayts);
      const sparedays = callength - index;
      if(sparedays < tdays) {
        this.adjustdays = tdays;
        this.daysAdd('add');
      }

      let tskindex = -1;
      const processarr = [];
      for (const task of this.timeTasks) {
        tskindex += 1;
        const tfirst = 0;
        const tfirstindex = 0;
        let cnt = 0;
        for (const tday of task.taskDays) {
          cnt += 1;
          if (tday.allocated !== 0) {
            if (tfirst == 0) {
              tfirst = new Date(tday.db);
              const indif = cnt - firstindex;
              const newindex = (index + indif) - 1;
              const newdate = this.Goal.goalDays[newindex].db;

              let usedate = { 
                db: newdate
              };
              this.shiftDo(task, usedate, newindex);
            }
          }
        }
      }
    },

    shiftprocess(tfirst) {

    },

    async processTime(force) {
      if (this.DueDate === null) {
        this.$buefy.toast.open({ message: "Please set a start date", position: "is-bottom", type: "is-danger" });
        return;
      }
      if (this.Goal.goalDays.length === 0) {
        await this.processDay(this.DueDate, force);
        await this.daysChange();
      } else {
        let gac = this.Goal.goalDays.length;
        for (const gday of this.Goal.goalDays) {
          const usedt = new Date(gday.db);
          gac -= 1;
         await this.processDay(usedt, force);
          if (gac === 0) {
            await this.daysChange();
          }
        }
      }
      this.isLoading = false;
      this.isFullLoading = false;
      const mn = document.getElementById('modalbody');
      if(this.lastScrollT != 0) {
        mn.scrollTop = this.lastScrollT;
      }
      if(this.lastScrollL != 0) {
        mn.scrollLeft = this.lastScrollL;
      }
    },

    processDay(date, force) {
      return new Promise(async (resolve, reject) => {
        const dayIn = this.getDateFull(date, "none", "none", 0);
        const dayofweekMC = dayIn.dayofweektxtshort;
        const dayofweek = dayIn.dayofweektxtlong.toLowerCase();
        if (this[dayofweek]) {
          const mins = this[`${dayofweek}minutes`];
          const dateout = `${dayIn.day}/${dayIn.month}`;
          const nd = {
            dayMC: dayofweekMC,
            day: dayofweek,
            db: dayIn.db,
            dateout,
            minutes: mins,
            allocated: 0,
            workflow: '---',
            shift: false,
            wizard1: false,
            wizard2: false,
            shiftselect: false,
          };
          if(!force) {
            this.Goal.goalDays.push(nd);
          }
          for (const rt of this.timeTasks) {
            const tday = {...nd};
            rt.taskDays.push(tday);
          }
          if(!force) {
            this.minsRemain = this.minsRemain - mins;
            if (this.minsRemain > 0) {
              const nextday = this.getDateFull(date, "add", "days", 1);
              await this.processDay(nextday.dtraw, force);
            }
          }
        } else {
          if(!force) {
            if (this.minsRemain > 0) {
              const nextday = this.getDateFull(date, "add", "days", 1);
              await this.processDay(nextday.dtraw, force);
            }
          }
        }
        
        resolve(dayofweek);
      });
    },

    async daysChange() {
      return new Promise(async (resolve, reject) => {
        for (const timetaskrow of this.timeTasks) {
          if (timetaskrow.taskDays.length !== 0) {
            for (const td of timetaskrow.taskDays) {
              if (timetaskrow.ondates.length !== 0) {
                for (const ondatesrow of timetaskrow.ondates) {
                  if( td.db === ondatesrow.dt ) {
                    td.allocated = ondatesrow.mins;
                    timetaskrow.allocatedmins += ondatesrow.mins;
                    td.workflow = ondatesrow.workflow;
                  }
                }
              }
            }
          }
        }
        resolve('done');
      });
    },

    async dayModal(task, day) {
      let usemins = task.totalmins - task.allocatedmins;
      if (day.allocated !== 0) {
        usemins = day.allocated;
      }

      this.modal2 = this.$buefy.modal.open({
        parent: this,
        component: ModalTime,
        hasModalCard: true,
        props: {
          data: {
            showDays: false,
            showHours: false,
            showMinutes: false,
            showMinutesFull: true,
            showNeeded: true,
            minsLimit: day.minutes,
            minsTotal: task.totalmins,
            minsAllocated: task.allocatedmins,
            mehours: this.Me.dayhours,
            inmins: usemins
          },
        },
        events: {
          timeSelected: async value => {
            day.allocated = value.totalmins;
            const tf = array.findLastIndex(task.ondates.dt, { 'dt': day.db });
            let found = false;
            for (const td of task.ondates) {
              if (td.dt === day.db) {
                found = true;
                td.mins = value.totalmins;
              }
            }
            if (!found) {
              const nd = {
                dt: day.db,
                tm: this.Me.daystart,
                mins: value.totalmins 
              }
              task.ondates.push(nd);
              const updstr = { _id: task._id, ondates: task.ondates };
              await this.DataSend('post', '/task/updatefew', updstr);
            }
            task.allocatedmins = 0;
            for (const tt of task.ondates) {
              task.allocatedmins += tt.mins;
            }

          }
        }
      });
    },
    
    updateAllocated() {

    },

    async dayPlus(task, day, inmins = 0) {
      clearTimeout(this.timer);
      if (inmins !== 0) {
        day.allocated += inmins;
        task.allocatedmins += inmins;
      } else {
        day.allocated += this.increment;
        task.allocatedmins += this.increment;
      }
      const oldarray = [...task.ondates];
      let found = false;
      for (const rw of task.ondates) {
        if(day.db === rw.dt) {
          rw.mins = day.allocated;
          found = true;
        }
      } 
      if (!found) {
        const nd = {
          dt: day.db,
          tm: this.Me.daystart,
          mins: day.allocated,
          workflow: task.workflow 
        }
        day.workflow = task.workflow;
        task.ondates.push(nd);
      }
      this.timer = setTimeout(async () => {
        const updstr = { _id: task._id, ondates: task.ondates };
        await this.DataSend('post', '/task/updatefew', updstr);
        this.timer = null;
      }, 2000);
    },

    async dayPlusNoSave(task, day, inmins = 15) {
      day.allocated += inmins;
      task.allocatedmins += inmins;
      const oldarray = [...task.ondates];
      let found = false;
      for (const rw of task.ondates) {
        if(day.db === rw.dt) {
          rw.mins = day.allocated;
          found = true;
        }
      } 
      if (!found) {
        const nd = {
          dt: day.db,
          tm: this.Me.daystart,
          mins: day.allocated,
          workflow: task.workflow 
        }
        day.workflow = task.workflow;
        task.ondates.push(nd);
      }
    },

    async dayMinus(task, day, reset = false) {
      clearTimeout(this.timer);
      if (day.allocated === 0) {
        return;
      }
      if (reset === true) {
        day.allocated = 0;
        task.allocatedmins = 0;
      } else {
        day.allocated -= this.increment;
        task.allocatedmins -= this.increment;
      }
      const oldarray = [...task.ondates];
      task.ondates = [];
      for (const rw of oldarray) {
        if(day.db === rw.dt) {
          if (day.allocated !== 0) {
            rw.mins = day.allocated;
            task.ondates.push(rw);
          }
        } else {
          task.ondates.push(rw);
        }
      }
      this.timer = setTimeout(async () => {
        const updstr = { _id: task._id, ondates: task.ondates };
        await this.DataSend('post', '/task/updatefew', updstr);
        this.timer = null;
      }, 2000);
    },
    
    async goalSave() {
      if (this.DueDate !== null) {
        const inst = this.getDateFull(this.DueDate, "none", "none", 0);
        this.Goal.duedate = inst.db;
        this.Goal.duedatets = inst.tsm;
      } else {
        this.Goal.duedate = "";
        this.Goal.duedatets = 0;
      }
      const data = {
        _id: this.Goal._id,
        daystotal: this.Goal.daystotal,
        dayshours: this.Goal.dayshours,
        daysminutes: this.Goal.daysminutes,
        duedate: this.Goal.duedate,
        duedatets: this.Goal.duedatets,
        goalDays: this.Goal.goalDays,
      }
      const upgoal = await this.DataSend('post', '/goal/updatefew', data);
    }
  }
};
</script>

<style>
.tblColumn {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-right: 1px solid #e6f6ff;
}
.tbltitle {
  padding: 3px;
  min-width: 440px;
  max-width: 440px;
  height: 35px;
  background-color: #ccedff;
  border-bottom: 1px white solid;
}
.tbltitlesel {
  padding: 3px;
  min-width: 440px;
  max-width: 440px;
  background-color: #ccedff;
  border-bottom: 1px white solid;
}
.tbltitletxt {
  height: 32px;
  overflow: hidden;
}
.tbltitletxtexpanded {
  height: 32px;
}
.tbwho {
  text-align: center !important;
  padding: 3px;
  min-width: 90px;
  background-color: #e6f6ff;
  border-bottom: 1px white solid;
  border-left: 1px white solid;
  border-right: 1px white solid;
  min-width: 100px;
}
.tbtime {
  text-align: center !important;
  padding: 3px;
  min-width: 90px;
  background-color: #e6f6ff;
  border-bottom: 1px white solid;
  border-left: 1px white solid;
  border-right: 1px white solid;
  width: 100px;
}
.tbfunction {
  text-align: center;
  padding: 3px;
  background-color: #e6f6ff;
  border-bottom: 1px white solid;
  border-right: 1px white solid;
  min-width: 110px;
}
.tbday {
  background-color: #e6f6ff;
  color: #99dcff;
}
.tbdayselected {
  background-color: #00a7ff !important;
  color: #ccedff;
}
</style>
