<template>
  <div class="modal-card" :style="ModalWidth">
    <header class="modal-card-head" style="padding: 10px;">
      <p class="modal-card-title">PEED</p>
    </header>
    <section class="modal-card-body">
      <div class="tab-item" v-if="loading">
        <b-progress type="is-info"></b-progress>
      </div>
      <div class="tile" v-if="!loading">
          <div class="tile is-6 p11 is-vertical">
            <div class="txth4 mb11">Info</div>

            <template>
              <!-- general -->
              <template>
                <!-- attach to goal: start -->
                <div class="txtsm mb5">Type: {{ActivePeed.type}}</div>
                <div class="txtsm mb5">Goal: <span v-if="ActivePeed.goaltitle !== ''">{{ActivePeed.goaltitle}}</span><span v-else>Not associated with a goal</span></div>
                <div style="height: 15px;"></div>
              </template>

              <!-- Profile Identification -->
              <template>
                <!-- Title: start -->
                <div class="txth5 txtbold mb5">{{ActivePeed.title}}</div>
                <!-- description -->
                <div v-if="ActivePeed.description !== ''" class="mb5">{{ActivePeed.description}}</div>
                <!-- age -->
                <template v-if="ActivePeed.type === 'Profile'">
                  <pillbox label="Age">
                    <span>{{ActivePeed.agestart}}</span>
                    <span v-if="ActivePeed.agestop !== 0"> - {{ActivePeed.agestop}}</span>
                  </pillbox>
                </template>
                
                <!-- gender -->
                <template v-if="ActivePeed.type === 'Profile'">
                  <pillbox label="Gender">{{ActivePeed.gendertxt}}</pillbox>
                </template>
                
                <!-- sexual orientation -->
                <template v-if="ActivePeed.type === 'Profile'">
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Sexual Orientation</div>
                  </div>
                  <div>
                    <div class="tsk p3 lgreybg m3" v-if="ActivePeed.sexualorientation.length === 0">Nothing selected</div>
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.sexualorientation" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                </template>

                <!-- ethnicity -->
                <template v-if="ActivePeed.type === 'Profile'">
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Ethnicity</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.ethnicity.length === 0">Nothing selected</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.ethnicity" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                </template>
                <!-- ethnicity: ends -->
                <!-- features: start -->
                <template>
                  <div class="mt5 mb5">
                    <template v-if="ActivePeed.type === 'Profile'">
                      <div class="txtbold mb3">Appearance</div>
                    </template>
                    <template v-if="ActivePeed.type !== 'Profile'">
                      <div class="txtbold mb3">Features</div>
                    </template>
                  </div>
                  <div>
                    <!-- <div class="tsk lgreybg m3" v-if="ActivePeed.features.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.features" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                </template>
                <!-- features: end -->

                <!-- dress: start -->
                <template>
                  <div class="mt5 mb5">
                    <template v-if="ActivePeed.type === 'Profile'">
                      <div class="txtbold mb3">Their style</div>
                    </template>
                    <template v-if="ActivePeed.type !== 'Profile'">
                      <div class="txtbold mb3">Benefits</div>
                    </template>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.dress.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.dress" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                </template>
                <!-- dress: end -->

                <div style="height: 15px;"></div>
              </template>
              
              <!-- Profile Accommodation -->
              <template v-if="ActivePeed.type !== 'Other'">
                <template>
                  <!-- Geo location -->
                  <template>
                    <div class="mt5 mb5">
                      <div class="txtbold mb3" v-if="ActivePeed.type === 'Profile'">Where do they live?</div>
                      <div class="txtbold mb3" v-if="ActivePeed.type === 'Product' || ActivePeed.type === 'Service'">Where will you be selling this {{ActivePeed.type}}?</div>
                      <div class="txtbold mb3" v-if="ActivePeed.type === 'Content' || ActivePeed.type === 'Advert'">Where will you be publishing this {{ActivePeed.type}}?</div>
                    </div>
                    <div>
                      <!-- <div class="tsk p3 lgreybg m3" v-if="ActivePeed.locations.length === 0">Nothing selected</div> -->
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.locations" :key="ti">
                        <div class="isFlexGrow">{{t.text}}</div>
                      </div>
                    </div>
                  </template>
                  <!-- accomodation -->
                  <template v-if="ActivePeed.type === 'Profile'">
                    <div class="mt5 mb5">
                      <div class="txtbold mb3">Accomodation</div>
                    </div>
                    <div>
                      <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.accomodation.length === 0">Nothing selected</div> -->
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.accomodation" :key="ti">
                        <div class="isFlexGrow">{{t.text}}</div>
                      </div>
                    </div>
                  </template>
                  
                  <div style="height: 15px;"></div>
                </template>
              </template>

              <!-- Profile Education -->
              <template v-if="ActivePeed.type === 'Profile'">
                <template>
                  <!-- Education -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Education</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.education.length === 0">Nothing selected</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.education" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>

                  <!-- employment -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Employment</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.employment.length === 0">Nothing selected</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.employment" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>

                  <!-- Job Role -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Profession</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.jobrole.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.jobrole" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>

                  <div style="height: 15px;"></div>
                </template>
              </template>
              

              <!-- Routine -->
              <template v-if="ActivePeed.type === 'Profile'">
                <template>
                  <!-- Morning: start -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Moring routine</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.morning.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.morning" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                  <!-- Morning: end -->
                  <!-- Midday: start -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Midday routine</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.midday.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.midday" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                  <!-- Midday: end -->
                  <!-- Afternoon: start -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Afternoon routine</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.afternoon.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.afternoon" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                  <!-- Afternoon: end -->
                  <!-- evening: start -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Evening routine</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.evening.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.evening" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                  <!-- evening: end -->
                  <div style="height: 15px;"></div>
                </template>
              </template>
              

              <!-- Consumption -->
              <template v-if="ActivePeed.type === 'Profile'">
                <template>
                  <!-- brandrate: start -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Brand importance</div>
                  </div>
                  <!-- brandrate: end -->
                  <!-- brands: start -->
                  <div class="mt5 mb5">
                    <div class="txtbold mb3">Brands</div>
                  </div>
                  <div>
                    <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.brands.length === 0">Nothing entered</div> -->
                    <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.brands" :key="ti">
                      <div class="isFlexGrow">{{t.text}}</div>
                    </div>
                  </div>
                  <!-- brands: end -->
                  <!-- shopping: start -->
                  <template>
                    <div class="mt5 mb5">
                      <div class="txtbold mb3">Buying habits</div>
                    </div>
                    <div>
                      <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.shopping.length === 0">Nothing entered</div> -->
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.shopping" :key="ti">
                        <div class="isFlexGrow">{{t.text}}</div>
                      </div>
                    </div>
                  </template>
                  <!-- shopping: end -->

                  <div style="height: 15px;"></div>
                </template>
              </template>
              
              <!-- Online presence and habits -->
              <template v-if="ActivePeed.type === 'Profile'">
                <template>
                  <!-- media: start -->
                  <template>
                    <div class="mt5 mb5">
                      <div class="txtbold mb3">Regulars</div>
                    </div>
                    <div>
                      <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.media.length === 0">Nothing entered</div> -->
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.media" :key="ti">
                        <div class="isFlexGrow">{{t.text}}</div>
                      </div>
                    </div>
                  </template>
                  <!-- media: end -->
                  <!-- platforms: start -->
                  <template>
                    <div class="mt5 mb5">
                      <div class="txtbold mb3">Social media</div>
                    </div>
                    <div>
                      <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.platforms.length === 0">Nothing entered</div> -->
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.platforms" :key="ti">
                        <div class="isFlexGrow">
                          <div>{{t.platform}}</div>

                          <div class="txtsm" v-if="t.followersfrom !== 0 && t.followersto !== 0">Has between {{t.followersfrom}} and {{t.followersto}} followers</div>
                          <div class="txtsm" v-if="t.followersfrom !== 0 && t.followersto === 0">Has more than {{t.followersfrom}} followers</div>
                          <div class="txtsm" v-if="t.followersfrom === 0 && t.followersto !== 0">Has less than {{t.followersto}} followers</div>

                          <div class="txtsm" v-if="t.followingfrom !== 0 && t.followingto !== 0">Follows between {{t.followingfrom}} and {{t.followingto}} profiles</div>
                          <div class="txtsm" v-if="t.followingfrom !== 0 && t.followingto === 0">Follows more than {{t.followingfrom}} profiles</div>
                          <div class="txtsm" v-if="t.followingfrom === 0 && t.followingto !== 0">Follows less than {{t.followingto}} profiles</div>

                          <div class="txtsm" v-if="t.postfrom !== 0 && t.poststo !== 0">Has between {{t.postfrom}} and {{t.poststo}} posts</div>
                          <div class="txtsm" v-if="t.postfrom !== 0 && t.poststo === 0">Has more than {{t.postfrom}} posts</div>
                          <div class="txtsm" v-if="t.postfrom === 0 && t.poststo !== 0">Has less than {{t.poststo}} posts</div>

                          <div class="txtsm" v-if="t.influencer">Influencer</div>

                          <div class="txtsm" v-if="t.socialusage">{{t.socialusage}}</div>
                          
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- platforms: end -->

                  <div style="height: 15px;"></div>
                </template>
              </template>

              <!-- Offline hangouts and habits: start -->
              <template v-if="ActivePeed.type === 'Profile'">
                <template>
                  <!-- hangouts: start -->
                  <template>
                    <div class="mt5 mb5">
                      <div class="txtbold mb3">Hangouts</div>
                    </div>
                    <div>
                      <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.hangouts.length === 0">Nothing entered</div> -->
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.hangouts" :key="ti">
                        <div class="isFlexGrow">{{t.text}}</div>
                      </div>
                    </div>
                  </template>
                  <!-- hangouts: end -->

                  <!-- habits: start -->
                  <template>
                    <div class="mt5 mb5">
                      <div class="txtbold mb3">Habits</div>
                    </div>
                    <div>
                      <!-- <div class="tsk p3 vlgreybg m3" v-if="ActivePeed.habits.length === 0">Nothing entered</div> -->
                      <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.habits" :key="ti">
                        <div class="isFlexGrow">{{t.text}}</div>
                      </div>
                    </div>
                  </template>
                  <!-- habits: end -->

                  <div style="height: 15px;"></div>
                </template>
              </template>
              <!-- Offline hangouts and habits: end -->

              <template v-if="sectioncareer">

                <div style="height: 15px;"></div>
              </template>
              



              <div style="height: 30px;"></div>
            </template>

          </div>
          <div class="tile is-6 p11 is-vertical">
            <div class="txth4 mb11">PEED</div>
            <div class="txt mb3">This is where we determine your value proposition in regards to the {{ActivePeed.type}}</div>

            <!-- painpoints: start -->
            <div class="mb11">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Painpoints</div>
                <div class="iconbutton" @click="(helppain) ? helppain=false:helppain=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helppain">What pain points are you fixing? </div>
                <div>
                  <!-- <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.painpoints.length === 0">No pain points defined</div> -->
                  <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.painpoints" :key="ti">
                    <div class="isFlexGrow">{{t.text}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- painpoints: end -->

            <!-- educate: start -->
            <div class="mb11">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Educate</div>
                <div class="iconbutton" @click="(helpeducate) ? helpeducate=false:helpeducate=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helpeducate">How are you going to educate this profile? What can you teach them?</div>
                <div>
                  <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.educate.length === 0">Nothing entered</div>
                  <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.educate" :key="ti">
                    <div class="isFlexGrow">{{t.text}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- educate: end -->

            <!-- entertain: start -->
            <div class="mb11">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Entertain</div>
                <div class="iconbutton" @click="(helpentertain) ? helpentertain=false:helpentertain=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helpentertain">How are you going to entertain this profile?</div>
                <div>
                  <!-- <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.entertain.length === 0">Nothing entered</div> -->
                  <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.entertain" :key="ti">
                    <div class="isFlexGrow">{{t.text}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- entertain: end -->

            <!-- desire: start -->
            <div class="mb11">
              <div class="txtbold bluebg10 white roundedtop p7 isFlex">
                <div class="isFlexGrow">Desire</div>
                <div class="iconbutton" @click="(helpdesire) ? helpdesire=false:helpdesire=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helpdesire">What is this profiles desires or what desire can you envoke in them. This is not the same as painpoints. What we are looking for here is what will make them associate with you, your product or service. What will make them a evangelist or an ambassador.</div>
                <div>
                  <!-- <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.desire.length === 0">Nothing entered</div> -->
                  <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.desire" :key="ti">
                    <div class="isFlexGrow">{{t.text}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- desire: end -->
            <!-- blockers: start -->
            <div class="mb11">
              <div class="txtbold redbg white roundedtop p7 isFlex">
                <div class="isFlexGrow">Blockers</div>
                <div class="iconbutton" @click="(helpblockers) ? helpblockers=false:helpblockers=true"><font-awesome-icon :icon="['fal', 'question-circle']" size="lg"></font-awesome-icon></div>
              </div>
              <div class="lgreybg roundedbottom p7">
                <div class="txtsm mt7 mb11" v-if="helpblockers">What are the main blockers you will experience? What obstacles do you have to overcome in order for this profile to buy/follow/read/sell what you have to offer?</div>
                <div>
                  <!-- <div class="tsk p3 vlgreybg midgrey m3" v-if="ActivePeed.blockers.length === 0">Nothing entered</div> -->
                  <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in ActivePeed.blockers" :key="ti">
                    <div class="isFlexGrow">{{t.text}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- blockers: end -->
          </div>
        </div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;" v-if="!loading"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Close
        </b-button>
      </div>
      <!-- <div>
        <b-button @click="submitForm(true)">
          Allocate
        </b-button>
      </div> -->
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";

Vue.component("pillbox", {
  template: "pillbox-template",
  props: {
    label: String,
    // value: undefined,
    width: { type: String, default: '100px' },
  },
  data: function () {
    return {
      lb: this.label,
      // vl: this.value,
      st: `width: ${this.width};`,
    }
  },
  template: `<div class="mb5 isFlex isFlexAlign"><div class="roundedleft lgreybg p5 pl11" style="width: 100px;">{{lb}}</div><div class="p5 isFlexGrow vlgreybg roundedright"><span><slot></slot></span></div></div>`});

export default {
  name: "ModalPeed",
  mixins: [Utils, Schemas],
  props: {
    peedid: String,
  },
  data() {
    return {
      ModalWidth: `width: auto`,
      usepeed: this.peedid,
      ActivePeed: {},
      loading: true,

      sectiongeneral: true,
      sectionprogen: false,
      sectionlocation: false,
      sectioncareer: false,
      sectionlikes: false,
      sectionmedia: false,
      sectionconsumption: false,
      sectionhabits: false,

      helppain: false,
      helpeducate: false,
      helpentertain: false,
      helpdesire: false,
      helpblockers: false,
    };
  },
  created() {
    this.DataLoad();
  },
  methods: {
    async DataLoad() {
      const gc = await this.DataGet(`/peed/${this.usepeed}`);

      this.ActivePeed = gc;
      this.setWidth();
      this.loading = false;
    },
    setWidth() {
      const inw = document.body.clientWidth;
      const inh = document.body.clientHeight;
      let newsize = `width: auto`;
      if (inw > 750) {
       const usize = inh - 100;
        newsize = `width: 700px; margin-left: 25px; margin-right: 25px; height: ${usize}px;`;
        // newsize = `width: ${usize}px; height: 80%;`;
      }
      this.ModalWidth = newsize;
    },
    async submitForm(close) {
      const updata = await this.DataSend("put", "/url", this.Data);
      let type = "update";
      if (this.Data._id === "new") {
        this.Data._id = updata.rows._id;
        this.Data.assignedto = updata.rows.assignedto;
        type = "new";
      }
      this.$emit("dataUpdated", { type, data: updata.rows });
      if (close) {
        this.$parent.close();
      }
    },
  }
};
</script>
